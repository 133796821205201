<script lang="ts" setup>
import {computed, ref, onMounted, onBeforeUnmount, nextTick} from 'vue';
import LazyImage from '@/vue/atoms/lazy-image.vue';
import ReccoMediaTypeAndDuration from '@/vue/molecules/recco/recco-media-type-and-duration.vue';
import {pagesPath} from '@/ts/router/pages-path';
import type {RouteLocationNamedRaw} from 'vue-router';
import type {ReccoRecommendationDTO} from '@/ts/types/dto/recco/recommendation.dto';

const props = defineProps<{
  recommendation: ReccoRecommendationDTO;
}>();

const idAria = computed(() => `recommendation_${props.recommendation?.id.catalogId}`);

const recLink = computed((): RouteLocationNamedRaw|null => {
  const routeName = pagesPath.main.recommendation;
  return {
    name: routeName,
    params: {
      itemId: props.recommendation.id.itemId,
      catalogId: props.recommendation.id.catalogId,
      contentType: props.recommendation.type,
    },
  };
});

const cardRef = ref<HTMLElement | null>(null);
const imageWidth = computed(() => {
  if (cardRef.value?.offsetWidth) {
    // Round to nearest 100px
    return Math.round(cardRef.value.offsetWidth / 100) * 100;
  }
  return 0;
});

// Update width on resize
let resizeObserver: ResizeObserver | null = null;

async function setupResizeObserver(): Promise<void> {
  // Wait for DOM update
  await nextTick();
  
  if (window.ResizeObserver && cardRef.value instanceof Element) {
    resizeObserver = new ResizeObserver(() => {
      // The computed will automatically update when cardRef.value.offsetWidth changes
    });
    resizeObserver.observe(cardRef.value);
  }
};

onMounted(async() => {
  await setupResizeObserver();
});

onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.disconnect();
    resizeObserver = null;
  }
});
</script>

<template>
  <router-link
    v-if="recLink"
    :to="recLink"
    class="rec-link"
    :aria-labelledby="idAria"
    :recommendation="recommendation"
  >
    <q-card
      ref="cardRef"
      flat
      class="rec-card"
    >
      <lazy-image
        format="webp"
        :src="recommendation.dynamicImageResizingUrl ?? ''"
        :width="imageWidth ?? undefined"
        class="img"
        loading="lazy"
        :alt="recommendation.imageAlt"
      />
      <div class="card-section-container">
        <q-card-section class="card-section">
          <recco-media-type-and-duration class="media-type" :type="recommendation.type" size="s"/>
          <div class="title-box">
            <span :id="idAria" class="title">
              {{ recommendation.headline }}
            </span>
          </div>
        </q-card-section>
      </div>
    </q-card>
  </router-link>
</template>

<style lang="scss" scoped>
.rec-link {
  @include focus-visible;
  border-radius: var(--card-border-radius);
}

.rec-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--Spacing-spacing-1);

  :deep(.q-card__section--vert) {
    padding: var(--Spacing-spacing-3) 0 0 0 0 !important;
  }
}

.card-section-container {
  width: 100%;
  padding-top: var(--Spacing-spacing-1);
}

.card-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: var(--Spacing-spacing-1);
  gap: 0 var(--Spacing-spacing-3);
}

.title-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.img {
  width: var(--Spacing-spacing-12);
  height: var(--Spacing-spacing-12);
  padding: 0;

  
  :deep(.q-img__container) {
    border-radius: var(--card-border-radius);
  }

  :deep(.q-skeleton) {
    border-radius: var(--card-border-radius);
  }
}

</style>
