<script lang="ts" setup>
import {useDrawerStore} from '@/ts/store/drawer-store';
import type {
  BalmSidebarMenuItem,
  BalmSidebarType,
} from '@/ts/types/component/balm-sidebar';


defineProps<{
  sidebarMenuItems: BalmSidebarMenuItem[];
}>();

const drawerStore = useDrawerStore();

function onClickItem(type: BalmSidebarType): void {
  drawerStore.openBalmSidebarDrawer(type);
}

</script>

<template>
  <q-list class="list">
    <q-item
      v-for="item of sidebarMenuItems"
      :key="item.type"
      class="list-item text-body"
      clickable
      @click="onClickItem(item.type)"
    >
      {{ $t(item.labelKey) }}
    </q-item>
  </q-list>
</template>

<style lang="scss" scoped>
.list {
  margin-top: var(--Spacing-spacing-3);
  padding-top: var(--Spacing-spacing-3);
  position: relative;
  &:after {
    content: "";
    border-top: 1px solid var(--Neutral-UI-neutral-ui-30);
    top: 0;
    left: var(--Spacing-spacing-3);
    right: var(--Spacing-spacing-3);
    position: absolute;
  }
}

.list-item {
  align-items: center;
  @include focus-visible;
}
</style>
