<script lang="ts" setup>
import {computed} from 'vue';
import {
  type InputSliderItem,
  InputSliderMarkerStyle,
  InputSliderSelectionStyle,
} from '@/ts/types/component/input-slider.type';
import HorizontalAxisWithScale from '@/vue/molecules/horizontal-axis-with-scale.vue';

const modelValue = defineModel<number>();

const props = defineProps<{
  items: InputSliderItem[];
  markerStyle: InputSliderMarkerStyle;
  selectionStyle: InputSliderSelectionStyle;
  labelledBy: string; // Resist the temptation to make this optional, ID of a HTML-element expected !
}>();

const selectedIndex = computed((): number => {
  return props.items.findIndex((item) => {
    return (modelValue.value as number|undefined) === item.value;
  });
});

const selectedItem = computed((): InputSliderItem => {
  return props.items[selectedIndex.value];
});

const axisScaleMarkerLabels = computed((): string[] => {
  if (props.markerStyle === InputSliderMarkerStyle.DISPLAY_INDEX) {
    return Array.from({length: props.items.length}, (_, index) => index.toString());
  }

  if (props.markerStyle === InputSliderMarkerStyle.DISPLAY_VALUE) {
    return props.items.map(item => item.value.toString());
  }

  return [];
});

const valueTextNumericPrefix = computed((): string => {
  if (props.selectionStyle === InputSliderSelectionStyle.INDEX_WITH_LABEL) {
    return selectedIndex.value?.toString() ?? '';
  }

  if (props.selectionStyle === InputSliderSelectionStyle.VALUE_WITH_LABEL) {
    return selectedItem.value?.value.toString();
  }

  return '';
});

function onUpdateSelectedIndex(index: number | null): void {
  modelValue.value = props.items[index ?? 0].value;
}

</script>

<template>
  <div class="input-slider">
    <q-slider
      :model-value="selectedIndex"
      class="slider"
      :min="0"
      :max="items.length - 1"
      track-size="2px"
      selection-color="transparent"
      :aria-valuetext="`${valueTextNumericPrefix}, ${selectedItem?.label}`"
      :aria-labelledby="labelledBy"
      @update:model-value="onUpdateSelectedIndex"
    />

    <horizontal-axis-with-scale
      v-if="markerStyle !== InputSliderMarkerStyle.HIDDEN"
      class="axis-scale"
      :marker-labels="axisScaleMarkerLabels"
      :selected-index="selectedIndex"
      @clickOnMarker="onUpdateSelectedIndex"
    />

    <div v-if="selectionStyle !== InputSliderSelectionStyle.HIDDEN" class="text-body-bold">
      <span class="selected-value-or-index">{{ valueTextNumericPrefix }}</span>
      <span>{{ selectedItem?.label }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.axis-scale {
  margin: -5px 0 var(--Spacing-spacing-4) 0;
}

.selected-value-or-index {
  &:after {
    content: " - ";
  }
}

.slider {
  &:deep(.q-slider__track) {
    background-color: var(--Neutral-UI-neutral-ui-10);
  }

  &:deep(.q-slider__thumb) {
    color: var(--Neutral-UI-neutral-ui-100);
  }
}

</style>