<script setup lang="ts">

import {useI18n} from 'vue-i18n';
import CurrentLabel from '@/vue/molecules/current-label.vue';
import CompletedProgress from '@/vue/atoms/completed-progress.vue';
import {useSessionsLinksId} from '@/ts/composables/stateful/use-links';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {computed} from 'vue';
import {SessionState} from '@/ts/types/dto/interventions-dto';
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import {useSessionId} from '@/ts/composables/stateful/use-session';
import LazyImage from '@/vue/atoms/lazy-image.vue';

const {t: $t} = useI18n();
const props = defineProps<{
  sessionId: UuidDTO;
}>();
const sessionId = computed(() => props.sessionId);
const {session, completedPercent, inProgress} = useSessionId(sessionId);
const {summaryLink} = useSessionsLinksId(sessionId);

const sessionClass = computed(() => {
  if (!session.value!.state) {
    return 'future';
  }
  const rec: Record<SessionState, string> = {
    [SessionState.HOMEWORK_PENDING]: 'current',
    [SessionState.COMPLETED]: 'past',
    [SessionState.LOCK]: 'future',
    [SessionState.NOT_JOINED]: 'future',
    [SessionState.UNLOCK]: 'current',
  };
  return rec[session.value!.state];
});
</script>
<template>
  <div class="session" :class="sessionClass">
    <div class="top">
      <div class="top-row">
        <lazy-image :src="session!.image" class="img"/>
        <current-label v-if="inProgress" theme="white"/>
      </div>
      <h5>{{ session!.name }}</h5>
      <div class="body" v-html="session!.description"/>
    </div>
    <div class="bottom">
      <completed-progress v-if="completedPercent" :completed="completedPercent" class="progress"/>
      <div class="complete">
        <svg-illustration name="check.svg"/> {{ $t('session_complete') }}
      </div>
      <balm-btn
        v-if="inProgress"
        paddings="p3-1"
        icon="arrow-narrow-right.svg"
        theme="white"
        class="btn"
        :label="$t('continue_session')"
        :to="summaryLink"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.top-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--Spacing-spacing-5);
}

.progress {
  margin-bottom: var(--Spacing-spacing-6);
}

h5 {
  font-size: 20px;
}

.body {
  margin: var(--Spacing-spacing-4) 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6;
  @include font-body-sm;

  @include screen-md {
    margin: var(--Spacing-spacing-2) 0 0 0;
    -webkit-line-clamp: 2;

    @media (min-height: 920px) {
      margin: var(--Spacing-spacing-4) 0;
      -webkit-line-clamp: 4;
    }
  }
}

.btn {
  width: 100%;
}
.img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.session {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--Spacing-spacing-6) var(--Spacing-spacing-4);
  font-size: 16px;
  max-height: 800px;
  width: 100%;
  flex-wrap: wrap;
  flex-shrink: 0;

  @include screen-md {
    padding: var(--Spacing-spacing-7);
    max-width: 367px;
  }

  .complete {
    display: none;
    @include font-body-bold;
    gap: var(--Spacing-spacing-2);
  }

  &.future {
    @include q-card-20;
  }

  &.current {
    @include q-card-blue;
  }

  &.past {
    @include q-card-green;

    .complete {
      display: flex;
    }

    .progress {
      display: none;
    }
  }
}
</style>
