<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script setup lang="ts">
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';
import type {Session} from '@/ts/types/dto/interventions-dto';

const props = defineProps<{
  session: Session | null
}>();

const {t: $t} = useI18n();
const unlockDate = computed<string | null>(() => {
  if (!props.session?.timeUntilUnlockMillis) {
    return null;
  }
  if (props.session!.timeUntilUnlockMillis > 3600 * 1000 * 99) {
    const date = new Date(props.session.unlockDate);
    return $t(
      'session_unlocks_date',
      {day: date.getDate(), month: date.getMonth() + 1},
    );
  }
  if (props.session.timeUntilUnlockMillis > 60 * 1000 * 99) {
    return $t(
      'session_unlocks_h',
      {hour: Math.ceil(props.session.timeUntilUnlockMillis / 3600 / 1000)},
    );
  }
  return $t(
    'session_unlocks_m',
    {min: Math.ceil(props.session.timeUntilUnlockMillis / 60 / 1000)},
  );
});
</script>
<template>
  <div v-if="unlockDate" class="unlocks">
    {{ unlockDate }}
  </div>
</template>
<style lang="scss" scoped>
.unlocks {
  border-radius: var(--button-border-radius);
  border: 2px solid var(--Neutral-UI-neutral-ui-70);
  display: flex;
  padding: var(--Spacing-spacing-3) var(--Spacing-spacing-7);
  justify-content: center;
  align-items: center;
  gap: 10px;
  @include font-body-bold;
}
</style>
