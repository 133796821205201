<script lang="ts" setup>
import {useLoadingNotification} from '@/ts/composables/pure/use-loading-notification';
import {useApi} from '@/ts/composables/stateful/use-api';
import {useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';
import {useRouter} from 'vue-router';
import {pagesPath} from '@/ts/router/pages-path';
import {SHOW_WITHDRAW_BTN} from '@/ts/utils/consts';

const api = useApi();

const {iid} = useRouterSectionParams();
const router = useRouter();
const [submit, loading] = useLoadingNotification(
  async() => api.me.withdrawIntervention(iid.value),
  () => void router.push(pagesPath.intervention.interventionsList),
);

</script>
<template>
  <q-item
    v-if="SHOW_WITHDRAW_BTN && iid"
    class="text-body-bold"
    dense
    :disabled="loading || undefined"
    clickable
    @click="submit"
  >
    {{ $t('withdraw_interven') }}
  </q-item>
</template>
