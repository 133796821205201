import {defineStore} from 'pinia';
import type {UserResponse} from '@/ts/types/dto/user-dto';
import type {EducationLevelDTO, UuidDTO} from '@/ts/types/dto/sign-in-dto';
import type {GenderDTO} from '@/ts/types/dto/gender-dto';
import type {ContentHomeResponse} from '@/ts/types/dto/home-dto';
import {hotjarInstance} from '@/ts/instances/hotjar-instance';

export interface UserData {
  id: UuidDTO;
  email: string;
  name: string;
  gender: GenderDTO;
  countryCode: string;
  birthYear: number;
  educationLevel: EducationLevelDTO;
  inProgressInterventionId: UuidDTO | null;
  marketingCodeExternalId: UuidDTO;
  registrationDate: string;
}

export interface UserStoreState {
  userData: UserData | null;
  homeContent: ContentHomeResponse | null;
  isReccoOnboardingCompleted: boolean;
}

export interface UserStoreActions {
  setUserData(ur: UserResponse | null): void;
  setHomeContent(ur: ContentHomeResponse | null): void;
  setReccoOnboardingCompletedState(value: boolean): void;
  logout(): void;
}

export const useUserStore = defineStore<'user-store', UserStoreState, any, UserStoreActions>('user-store', {
  state: () => ({
    userData: null,
    homeContent: null,
    isReccoOnboardingCompleted: false,
  }),
  actions: {
    setHomeContent(chr: ContentHomeResponse | null) {
      if (chr) {
        this.homeContent = chr;
      } else {
        this.homeContent = null;
      }
    },
    setUserData(ur: UserResponse | null) {
      if (!ur) {
        this.userData = null;
        return;
      }
      this.userData = ur;
      hotjarInstance.setInfo(ur);
    },
    setReccoOnboardingCompletedState(value: boolean): void {
      this.isReccoOnboardingCompleted = value;
    },
    logout(): void {
      // setter calling is required, to trigger inner attributes
      this.setHomeContent(null);
      this.setUserData(null);
    },
  },
});
