<script setup lang="ts">
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';

defineProps<{
  to?: string;
  label: string;
}>();
</script>
<template>
  <div class="back-link">
    <RouterLink v-if="to" :to="to" class="link">
      <svg-illustration class="back-arrow" name="chevron-left.svg"/>
      <span class="text-heading-3 label">{{ label }}</span>
    </RouterLink>

    <span v-if="!to" class="text-heading-3 label label--linkless">{{ label }}</span>
    <svg-illustration name="right-down-arrow.svg" inner-class="inner-right"/>
  </div>
</template>
<style lang="scss" scoped>
.back-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: var(--Spacing-spacing-2);

  @include screen-lg {
    margin-bottom: var(--Spacing-spacing-4);
  }
}

.link {
  display: flex;
  color: var(--Neutral-UI-neutral-ui-50);
  align-items: center;
  margin-right: var(--Spacing-spacing-2);
  border-radius: 4px;
  @include focus-visible;

  &:hover .label {
    text-decoration: underline;
  }
}

.label {
  color: var(--Neutral-UI-neutral-ui-100);

  &--linkless {
    // for legacy reasons, back link should always have a link
    margin-right: var(--Spacing-spacing-2);
  }
}

.back-arrow {
  position: relative;
  left: -6px;
}

</style>
