<script lang="ts" setup>
import de from 'svg-country-flags/svg/de.svg';
import us from 'svg-country-flags/svg/us.svg';
import {useLang} from '@/ts/composables/stateful/use-lang';
import {Language} from '@/ts/types/component/localization.type';
import {MULTI_LANGUAGE} from '@/ts/utils/consts';
import {QBtn, QItem, useQuasar} from 'quasar';
import {useI18n} from 'vue-i18n';
import {useRouter} from 'vue-router';

defineProps<{
  displayAsListItem?: boolean;
}>();

const {lang, setLanguage} = useLang();
const $q = useQuasar();
const {t: $t} = useI18n();

const router = useRouter();

function switchLang(): void {
  if (lang.value === Language.German) {
    setLanguage(Language.English);
  } else {
    setLanguage(Language.German);
  }
  $q.notify({
    message: $t('languages_has_been'),
    position: 'top-left',
    actions: [{
      label: $t('refresh_the_page'),
      // eslint-disable-next-line no-undefined
      handler: (): void => { router.go(undefined!); },
    }],
  });
}
</script>
<template>
  <component
    :is="displayAsListItem ? QItem : QBtn"
    v-show="MULTI_LANGUAGE"
    class="item text-body-bold"
    flat
    clickable
    @click="switchLang"
  >
    <span class="label">{{ $t('change_language_label') }}</span>
    <img v-if="lang === Language.German" :src="de" class="flag"/>
    <img v-else :src="us" class="flag"/>
  </component>
</template>
<style lang="scss" scoped>
.item {
  align-items: center;
  @include focus-visible;
}

.flag {
  cursor: pointer;
  width: 20px;
}
.label {
  margin-right: var(--Spacing-spacing-2);
}
</style>
