<script lang="ts" setup>
import {computed} from 'vue';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import {ReccoQuestionnaireCategory} from '@/ts/types/component/recco-questionnaire.type';
import BalmBtn from '@/vue/atoms/balm-btn.vue';

const props = defineProps<{
  category: ReccoQuestionnaireCategory;
}>();

const emit = defineEmits<{
  done: [],
}>();

const headlineLangKey = computed((): string => {
  return {
    [ReccoQuestionnaireCategory.NUTRITION]: 'recco_assessment_nutrition',
    [ReccoQuestionnaireCategory.MENTAL_WELLBEING]: 'recco_assessment_mental_wellbeing',
    [ReccoQuestionnaireCategory.SLEEP]: 'recco_assessment_sleep',
    [ReccoQuestionnaireCategory.PHYSICAL_ACTIVITY]: 'recco_assessment_physical_activity',
    [ReccoQuestionnaireCategory.ONBOARDING]: 'recco_assessment_onboarding',
  }[props.category] ?? 'recco_assessment_onboarding';
});

</script>

<template>
  <div class="recco-questionnaire-outro">
    <div class="svg-container">
      <svg-illustration name="portrait2.svg"/>
    </div>
    <div class="text-h6">
      {{ $t(headlineLangKey) }}
    </div>
    <balm-btn
      :label="$t('recco_finish_asessment_btn')"
      @click="emit('done')"
    />
  </div>
</template>

<style lang="scss" scoped>

.recco-questionnaire-outro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
  gap: var(--Spacing-spacing-4);
}

.svg-container {
  width: 200px;
}


</style>
