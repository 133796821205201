import type {ReccoRecommendationDTO, ReccoRecommendationTypeDTO} from '@/ts/types/dto/recco/recommendation.dto';
import type {ReccoArticleDTO} from '@/ts/types/dto/recco/article.dto';
import type {ReccoAudioDTO} from '@/ts/types/dto/recco/audio.dto';
import type {ReccoVideoDTO} from '@/ts/types/dto/recco/video.dto';
import {type ComputedRef, ref, computed} from 'vue';
import {useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';
import {useApi} from '@/ts/composables/stateful/use-api';
import {useLogger} from '@/ts/composables/pure/use-logger';


interface RecommendationParams {
  catalogId: string;
  itemId: string;
  contentType: ReccoRecommendationTypeDTO;
}

export function useProvidedRecommendation(recommendation: ReccoRecommendationDTO | null): UseProvidedRecommendationRes {
  const logger = useLogger('useProvidedRecommendation');
  const isLoading = ref(!recommendation);
  const currentRecommendation = ref<ReccoRecommendationDTO | null>(recommendation);
  const api = useApi();

  // Get router params
  const routerParams = useRouterSectionParams();

  // Create computed params that unwraps the ComputedRefs from router params
  const params = computed<RecommendationParams>(() => {
    if (recommendation?.id) {
      return {
        catalogId: recommendation.id.catalogId,
        itemId: recommendation.id.itemId,
        contentType: recommendation.type,
      };
    }
    
    // Unwrap the ComputedRef values from router params
    return {
      catalogId: routerParams.catalogId.value,
      itemId: routerParams.itemId.value,
      contentType: routerParams.contentType.value as ReccoRecommendationTypeDTO,
    };
  });

  async function fetchRecommendation(): Promise<void> {
    logger.debug('Fetching recommendation details', {contentType: params.value.contentType})();
    
    try {
      isLoading.value = true;
      logger.debug('Fetching recommendation data', {contentType: params.value.contentType})();
      
      let fetchedRecommendation: ReccoRecommendationDTO;
      
      switch (params.value.contentType) {
        case 'articles' as ReccoRecommendationTypeDTO:
          logger.debug('Fetching article', {catalogId: params.value.catalogId, itemId: params.value.itemId})();
          fetchedRecommendation = await api.recco.getArticle(
            params.value.catalogId, 
            params.value.itemId,
          );
          break;
        case 'audios' as ReccoRecommendationTypeDTO:
          logger.debug('Fetching audio', {catalogId: params.value.catalogId, itemId: params.value.itemId})();
          fetchedRecommendation = await api.recco.getAudio(
            params.value.catalogId, 
            params.value.itemId,
          );
          break;
        case 'videos' as ReccoRecommendationTypeDTO:
          logger.debug('Fetching video', {catalogId: params.value.catalogId, itemId: params.value.itemId})();
          fetchedRecommendation = await api.recco.getVideo(
            params.value.catalogId, 
            params.value.itemId,
          );
          break;
        default:
          logger.warn('Unknown content type', {contentType: params.value.contentType})();
          throw new Error(`Unsupported content type: ${params.value.contentType}`);
      }
      
      // Always update with the fetched data, which should have all fields
      currentRecommendation.value = fetchedRecommendation;
    } catch (error) {
      logger.error('Error fetching recommendation', {error, contentType: params.value.contentType})();
      throw error;
    } finally {
      if (isLoading.value) {
        isLoading.value = false;
      }
    }
  }

  return {
    currentRecommendation: computed(() => currentRecommendation.value),
    isLoading: computed(() => isLoading.value),
    recommendationType: computed(() => params.value.contentType),
    fetchRecommendation,
  };
}

export interface UseProvidedRecommendationRes {
    currentRecommendation: ComputedRef<ReccoArticleDTO | ReccoAudioDTO | ReccoVideoDTO | null>;
    isLoading: ComputedRef<boolean>;
    recommendationType: ComputedRef<ReccoRecommendationTypeDTO>;
    fetchRecommendation(): Promise<void>;
  }