import {useApi} from '@/ts/composables/stateful/use-api';
import {useUserStore} from '@/ts/store/user-store';

export function useReccoOnboardingState(): {syncReccoOnboardingState(): Promise<boolean>} {
  const api = useApi();
  const userStore = useUserStore();

  const syncReccoOnboardingState = async(): Promise<boolean> => {
    if (userStore.isReccoOnboardingCompleted) {
      // the state can not switch back from true to false, for that reason is no API-request necessary.
      return true;
    }

    try {
      const reccoMe = await api.me.getReccoMe();
      userStore.setReccoOnboardingCompletedState(reccoMe.isOnboardingQuestionnaireCompleted);
      return reccoMe.isOnboardingQuestionnaireCompleted;
    } catch (err) {
      throw new Error();
    }
  };

  return {
    syncReccoOnboardingState,
  };
}