<script setup lang="ts">
import {SessionState, type Session} from '@/ts/types/dto/interventions-dto';
import {useI18n} from 'vue-i18n';
import LazyImage from '@/vue/atoms/lazy-image.vue';
import {computed} from 'vue';
import {useSessionId} from '@/ts/composables/stateful/use-session';
import {useSessionsLinksId} from '@/ts/composables/stateful/use-links';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import CompletedProgress from '@/vue/atoms/completed-progress.vue';
import BalmBtn from '@/vue/atoms/balm-btn.vue';

const {t: $t} = useI18n();

const props = defineProps<{ session: Session, index: number }>();

const sessionId = computed(() => props.session.id);
const {completedPercent, inProgress} = useSessionId(sessionId);
const {summaryLink} = useSessionsLinksId(sessionId);

const theme = computed(() => {
  if (props.session.state === SessionState.UNLOCK) {
    return 'blue';
  }
  if (props.session.state === SessionState.COMPLETED) {
    return 'green';
  }
  return 'grey';
});

</script>

<template>
  <div class="session" :class="theme">
    <lazy-image :src="session!.image" class="img"/>
    <p class="session-index">
      {{ `${$t('session')} ${index + 1}` }}
    </p>
    <p class="session-name">
      {{ session?.name }}
    </p>
    <div class="status">
      <div v-if="session?.state === SessionState.COMPLETED" class="completed">
        <svg-illustration name="check.svg"/> {{ $t('session_complete') }}
      </div>
      <div v-else-if="inProgress" class="in-progress">
        <completed-progress v-if="completedPercent" :completed="completedPercent" class="progress"/>
        <balm-btn
          paddings="none"
          icon="arrow-narrow-right.svg"
          theme="plain-underline"
          class="btn"
          :to="summaryLink"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.session {
  width: 248px;
  min-height: 260px;
  border-radius: var(--Border-Radius-3);
  background-color: var(--Neutral-UI-neutral-ui-10);
  display: flex;
  flex-direction: column;
  padding: 12px 12px 16px 12px;

  @include screen-md {
    padding: 16px 16px 24px 16px;
  }

  @include screen-lg {
    padding: 24px 24px 32px 24px;
  }

  &.blue {
    background-color: var(--Secondary-secondary-color-1);
  }
  
  &.green {
    background-color: var(--Secondary-secondary-color-2);
  }
}

.img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-bottom: 8px;

  @include screen-md {
    width: 24px;
    height: 24px;
    margin-bottom: 12px;
  }

  @include screen-lg {
    width: 32px;
    height: 32px;
    margin-bottom: 16px;
  }
}

.session-index {
  @include font-body-sm-bold;
  margin: 0;
}

.session-name {
  @include font-body-sm-bold;
  margin: 0;
}

.status {
  margin-top: auto;
}

.progress {
  width: 50%;
}

.completed {
  display: flex;
  gap: 8px;
}

.in-progress {
  display: flex;
  justify-content: space-between;
}

</style>
