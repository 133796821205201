<script lang="ts" setup>
import type {ReccoRecommendationDTO} from '@/ts/types/dto/recco/recommendation.dto';
import LoadingError from '@/vue/templates/loading-error.vue';
import {getRandomId} from '@/ts/utils/pure-functions';
import ReccoArticleComponent from '@/vue/molecules/recco/recco-article-component.vue';
import {useProvidedRecommendation} from '@/ts/composables/pure/use-provided-recommendation';
import {useLogger} from '@/ts/composables/pure/use-logger';
import {onMounted} from 'vue';

const logger = useLogger('RecommendationPage');

const props = defineProps<{
    recommendation?: ReccoRecommendationDTO;
}>();

const {currentRecommendation, isLoading, fetchRecommendation} = useProvidedRecommendation(props.recommendation!);

onMounted(() => {
  logger.debug('Component mounted, initiating fetch')();
  fetchRecommendation().catch((error: unknown) => {
    logger.error('Unhandled error in fetchRecommendation', {error})();
  });
});

logger.debug('Setting up recommendation response', { 
  hasCurrentRecommendation: Boolean(currentRecommendation.value),
})();


const labelledById = getRandomId();

</script>

<template>
  <loading-error :id="labelledById" :loading="isLoading" type="page">
    <template #default>
      <div v-if="currentRecommendation" class="recommendation-page">
        <recco-article-component :article="currentRecommendation"/>
      </div>
    </template>
  </loading-error>
</template>
<style lang="scss" scoped>
.recommendation-page {
  @include page-alignment;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>