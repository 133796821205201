<script lang="ts" setup>

import {watch} from 'vue';
import {ColorScheme, useUserPreferencesStore} from '@/ts/store/user-preferences.store';
import {storeToRefs} from 'pinia';
import {useQuasar} from 'quasar';

const userPreferencesStore = useUserPreferencesStore();
const {colorScheme} = storeToRefs(userPreferencesStore);

const $q = useQuasar();

watch(colorScheme, (value) => {
  document.body.classList.remove('force-light-mode');
  document.body.classList.remove('force-dark-mode');
  if (value === ColorScheme.dark) {
    document.body.classList.add('force-dark-mode');
    $q.dark.set(true); // switch Quasar components
  } else if (value === ColorScheme.light) {
    document.body.classList.add('force-light-mode');
    $q.dark.set(false); // switch Quasar components
  } else {
    $q.dark.set('auto'); // switch Quasar components
  }
}, {immediate: true});

</script>

<template>
  <div class="page-container">
    <router-view/>
  </div>
</template>
<style lang="scss" scoped>
.page-container {
  display: flex;
  max-width: 100%;
  height: 100%;
}
</style>
