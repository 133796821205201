<script setup lang="ts">

defineProps<{
  name?: string;
  exampleHtml: string;
}>();

</script>
<template>
  <div class="example">
    <p class="example-title">
      {{ $t('example') }} <span v-if="name">{{ name }}</span>
    </p>
    <p class="example-body" v-html="exampleHtml"/>
  </div>
</template>
<style lang="scss" scoped>
.example {
  padding: var(--Spacing-spacing-4);
  @include font-body;
  @include q-card-20;
  > .example-body {
    @include font-body;
  }
}

.example-title {
  @include font-body-bold;
}
</style>
