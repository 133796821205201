import type {ReccoRatingDTO} from '@/ts/types/dto/recco/rating.dto';

export type ReccoRecommendationResponse = ReccoRecommendationDTO[];

export interface ReccoRecommendationDTO {
  id: ReccoIdDTO;
  type: ReccoRecommendationTypeDTO;
  rating: ReccoRatingDTO;
  status: string;
  bookmarked: boolean;
  headline: string;
  description?: string | null;
  imageUrl: string | null;
  imageAlt: string | null;
  dynamicImageResizingUrl: string | null;
  topic?: string | null;
  
}

export interface ReccoIdDTO {
  itemId: string;
  catalogId: string;
}

export enum ReccoRecommendationTypeDTO {
  Article = 'articles',
  Questionnaire = 'questionnaires',
  Audio = 'audios',
  Video = 'videos',
}


