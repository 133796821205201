<script setup lang="ts">
import ExampleBlock from '@/vue/molecules/components/helpers/example-block.vue';
import {useRequiredRule} from '@/ts/composables/pure/use-input-rules';
import FlatIconBtn from '@/vue/molecules/flat-icon-btn.vue';
import BalmBtn from '@/vue/atoms/balm-btn.vue';

defineProps<{
  name: string;
  exampleHtml?: string | null;
  descriptionHtml?: string | null;
}>();

const model = defineModel<string[]>();
const requiredRule = useRequiredRule();

function deleteItem(int: number): void {
  model.value!.splice(int, 1);
}
</script>
<template>
  <div class="example-multiple-input">
    <div class="instructions">
      <h5 class="title">
        {{ name }}
      </h5>
      <div v-if="descriptionHtml" class="description" v-html="descriptionHtml"/>
    </div>
    <example-block v-if="exampleHtml" class="example" :example-html="exampleHtml"/>
    <div class="inputs">
      <div v-for="(item, i) in model" :key="i" class="each-row">
        <flat-icon-btn
          v-if="(i + 1) === model!.length && i >= 1"
          class="trash"
          icon="trash.svg"
          @click="deleteItem(i)"
        />
        <q-input
          v-model="model![i]"
          :rules="[requiredRule]"
          type="textarea"
          borderless
        />
      </div>
    </div>
    <balm-btn
      theme="grey"
      :label="$t('add_item')"
      class="btn"
      icon="plus.svg"
      @click="model!.push('')"
    />
  </div>
</template>
<style scoped lang="scss">
.example-multiple-input {
  @include q-card-white;
  padding: var(--Spacing-spacing-4);
  display: grid;
  gap: var(--Spacing-spacing-4);

  @include screen-lg {
    padding: var(--Spacing-spacing-7);
    grid-template-columns: 2fr 1fr;
  }
}

.title {
  color: var(--Neutral-UI-neutral-ui-100);
  margin-bottom: var(--Spacing-spacing-4);
}

.instructions {
  @include screen-lg {
    grid-column: 1;
    grid-row: 1;
  }
}

.example {
  @include screen-lg {
    grid-column: 2;
    grid-row: span 2;
    height: fit-content;
  }
}

.trash {
  position: absolute;
  width: 20px;
  z-index: 2;
  top: var(--Spacing-spacing-2);
  right: var(--Spacing-spacing-2);
  cursor: pointer;
}

.each-row {
  position: relative;
}

.inputs {
  gap: var(--Spacing-spacing-2);
  display: flex;
  flex-direction: column;

  @include screen-lg {
    grid-column: span 2;
    grid-row: 3;
  }

  :deep(textarea) {
    padding: var(--Spacing-spacing-4);
    background-color: var(--Neutral-UI-neutral-ui-10);
    color: var(--Neutral-UI-neutral-ui-100);
  }
}

.btn {
  margin-left: auto;
  
  @include screen-lg {
    grid-column: 2;
  }
}

</style>
