<script lang="ts" setup>
import type {ReccoRecommendationDTO} from '@/ts/types/dto/recco/recommendation.dto';
import RecommendationCardStandard from '@/vue/molecules/recco/recommendation-cards/recommendation-card-standard.vue';

const props = defineProps<{
  recommendations: ReccoRecommendationDTO[];
}>();

</script>

<template>
  <div class="recommendations-container">
    <q-scroll-area 
      horizontal
      class="scroll-area"
      :thumb-style="{display: 'none'}"
      :bar-style="{display: 'none'}"
      tabindex="-1"
    >
      <div class="recommendations-list" role="list">
        <recommendation-card-standard
          v-for="recommendation in props.recommendations"
          :key="recommendation.id.catalogId"
          :recommendation="recommendation"
        />
      </div>
    </q-scroll-area>
  </div>
</template>

<style lang="scss" scoped>
.recommendations-container {
  --negative-margin-to-avoid-clipping-keyboard-focus-outline: 5px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
}

.scroll-area {
  width: calc(100% + 2 * var(--negative-margin-to-avoid-clipping-keyboard-focus-outline));
  margin: 0 calc(var(--negative-margin-to-avoid-clipping-keyboard-focus-outline) * -1);
  position: relative;

  :deep(.q-scrollarea__content) {
    min-height: 100%;
  }

  &:before,
  &:after {
    content: "";
    width: 5px;
    position: absolute;
    height: 100%;
    z-index: 2;
    top: 0;
  }

  &:before {
    left: 0px;
    background: linear-gradient(
      to right, var(--body-background-color) 0%, color-mix(in srgb, var(--body-background-color), transparent 100%
    ) 100%);
  }

  &:after {
    right: 0;
    background: linear-gradient(
      to left, var(--body-background-color) 0%, color-mix(in srgb, var(--body-background-color), transparent 100%
    ) 100%);
  }
}

.recommendations-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--Spacing-spacing-4);
  padding: var(--Spacing-spacing-2) var(--negative-margin-to-avoid-clipping-keyboard-focus-outline);
}

:deep(.rec-card) {
  background: transparent !important;
}
</style>
