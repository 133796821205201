<script setup lang="ts">
// TODO refactor that shit
import SectionComponent from '@/vue/molecules/components/section-component.vue';
import {useLoadingNotification} from '@/ts/composables/pure/use-loading-notification';
import {useApi} from '@/ts/composables/stateful/use-api';
import {useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';
import {
  computed,
  ref,
} from 'vue';
import type {
  Component,
  Intervention,
} from '@/ts/types/dto/interventions-dto';
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import {useInterventionStore} from '@/ts/store/intervention-store';
import {useSessionId} from '@/ts/composables/stateful/use-session';
import LoadingError from '@/vue/templates/loading-error.vue';
import {useLoadingDataRefNull} from '@/ts/composables/pure/use-loading-data-ref';
import BalmDialog from '@/vue/atoms/balm-dialog.vue';
import type {SessionRequest} from '@/ts/types/dto/session-request-dto';
import {useLogger} from '@/ts/composables/pure/use-logger';
import type {ComponentPublicInstance} from '@vue/runtime-core';
import {useScrollTo} from '@/ts/composables/pure/use-scroll-to';
import {getId} from '@/ts/composables/pure/use-get-id';

const isOpen = defineModel<boolean>();

const api = useApi();

const {sid, iid} = useRouterSectionParams();
const hideContinue = ref(false);

const model = ref<SessionRequest[][]>([]);
const intervention = useInterventionStore();

const currentIndex = ref(1);  // image no text should come with 2nd component
const form = ref<ComponentPublicInstance>(null!);

const {session} = useSessionId(sid);

if (session.value!.homework[1]) {
  throw Error('Multiple homeworks are not supported');
}

const components = computed<Component[]>(() => {
  return session.value!.homework[0].pages.flatMap(homework => homework.components);
});

const allContentRead = computed(() => {
  return currentIndex.value >= components.value.length;
});

const [_, loading, error] = useLoadingDataRefNull(async() => {
  if (!session.value!.homework[0].userHomeworkId) {
    const homework = await api.me.postHomeWork(
      iid.value,
      sid.value,
    );
    intervention.value!.sessions.find(ses => ses.id === sid.value)!.homework = [homework];
  }
});
const isSubmitLabel = computed(() => currentIndex.value >= components.value.length - 1);

// eslint-disable-next-line no-magic-numbers
const scrollNext = useScrollTo(form, 100);
const logger = useLogger();
const [saveSession, savingInProgress] = useLoadingNotification(
    async(): Promise<Intervention> => {
      const currentModel: SessionRequest[] | null = model.value[currentIndex.value] ?? null;
      logger.log(`Increasing currentIndex ${currentIndex.value}`)();
      currentIndex.value++;
      if (!allContentRead.value) {
        scrollNext();
      }
      // we have temporary answers or we are at the last component
      if ((currentModel?.length ?? 0) > 0 || allContentRead.value) {
        const answers: SessionRequest[] = model.value
          .flatMap((val: SessionRequest[]) => val)
          .filter((el: SessionRequest | null) => el);
        logger.log('Saving homework {}', answers)();
        await api.me.putHomework(
          iid.value,
          sid.value,
          session.value!.homework[0].userHomeworkId!, // shouldbeset onbeforemount
          {
            answers,
            isContentRead: allContentRead.value,
          },
        );
        return api.me.getIntervention(iid.value);
      }
      return intervention.value!;
    },
    (newIntervention) => {
      if (allContentRead.value) {
        intervention.value = newIntervention;
        currentIndex.value = 0;
        model.value = [];
        isOpen.value = false;
      }
    },
  )
;

function onValidationError(element: any): void {
  ((element as ComponentPublicInstance).$el as HTMLElement).scrollIntoView({behavior: 'smooth'});
}

</script>
<template>
  <loading-error id="homework-modal" :loading="loading" :error="error" type="component">
    <balm-dialog v-model="isOpen">
      <q-form
        ref="form"
        class="qform"
        @submit="saveSession"
        @validation-error="onValidationError"
      >
        <div class="homework-form">
          <template v-for="(component, i) in components">
            <!-- Should be v-if instead of v-show cause of form-submit -->
            <section-component
              v-if="i <= currentIndex"
              :key="getId(component)"
              v-model="model[i]"
              v-model:hide-continue="hideContinue"
              :sid="sid"
              :iid="iid"
              :primary-feelings="intervention!.primaryFeelings"
              :loading="false"
              :thinking-traps="intervention!.thinkingTraps"
              :component="component"
            />
          </template>
          <balm-btn
            v-if="!hideContinue"
            theme="white"
            paddings="p3-7"
            class="continue-btn"
            :label="isSubmitLabel ? $t('submit') : $t('continue')"
            type="submit"
            :loading="savingInProgress"
          />
        </div>
      </q-form>
    </balm-dialog>
  </loading-error>
</template>
<style scoped lang="scss">
.qform {
  gap: var(--Spacing-spacing-4);
}

.homework-form {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-4)
}

.continue-btn {
  margin-left: auto;
  margin-top: var(--Spacing-spacing-5);
  margin-bottom: var(--Spacing-spacing-1);
}
</style>
