<script lang="ts" setup>
import type {Question} from '@/ts/types/dto/interventions-dto';
import {computed} from 'vue';

const props = defineProps<{
  question: Question;
}>();

computed(() => props.question.multiChoice.options);
const isTrue = computed(() => Boolean(props.question.multiChoice.options
  .find(opt => props.question.multiChoiceSelectedIds[0] === opt.id)!.value));
</script>

<template>
  <div class="read-only-grid-card" :class="{active: isTrue}">
    <div class="quest-head">
      {{ question.name }}
    </div>
    <div class="quest-body" v-html="question.description"/>
  </div>
</template>
<style lang="scss" scoped>
.quest-head {
  @include font-body-bold;
}
.quest-body {
  @include font-body-sm;
}
.read-only-grid-card {
  border-radius: var(--card-border-radius);
  padding: var(--Spacing-spacing-7);
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-3);

  &.active {
    background-color: var(--Secondary-secondary-color-5);
  }

  &:not(.active) {
    background-color: var(--Neutral-UI-neutral-ui-0);
  }
}
</style>
