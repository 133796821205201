<script lang="ts" setup>

import {ReccoQuestionnaireCategory} from '@/ts/types/component/recco-questionnaire.type';
import {computed} from 'vue';
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';

const props = defineProps<{
  category: ReccoQuestionnaireCategory;
}>();

const emit = defineEmits<{
  clickContinue: [],
}>();

const headlineLangKey = computed((): string => {
  return {
    [ReccoQuestionnaireCategory.NUTRITION]: 'recco_assessment_nutrition',
    [ReccoQuestionnaireCategory.MENTAL_WELLBEING]: 'recco_assessment_mental_wellbeing',
    [ReccoQuestionnaireCategory.SLEEP]: 'recco_assessment_sleep',
    [ReccoQuestionnaireCategory.PHYSICAL_ACTIVITY]: 'recco_assessment_physical_activity',
    [ReccoQuestionnaireCategory.ONBOARDING]: 'recco_assessment_onboarding',
  }[props.category] ?? 'recco_assessment_onboarding';
});

const svgName = computed((): string => {
  return {
    [ReccoQuestionnaireCategory.NUTRITION]: 'topic-nutrition.svg',
    [ReccoQuestionnaireCategory.MENTAL_WELLBEING]: 'topic-mental-wellbeing.svg',
    [ReccoQuestionnaireCategory.SLEEP]: 'topic-sleep.svg',
    [ReccoQuestionnaireCategory.PHYSICAL_ACTIVITY]: 'topic-physical-activity.svg',
    [ReccoQuestionnaireCategory.ONBOARDING]: 'topic-onboarding.svg',
  }[props.category] ?? 'topic-onboarding.svg';
});

</script>

<template>
  <div class="recco-questionnaire-intro">
    <div class="welcome-text">
      <h2 class="text-display-2 headline">
        {{ $t(headlineLangKey) }}
      </h2>

      <div class="text-caption-sm caption">
        {{ $t('recco_assessment_welcome_caption') }}
      </div>

      <p class="copy">
        {{ $t('recco_assessment_welcome') }}
      </p>

      <balm-btn :label="$t('recco_assessment_cta')" @click="emit('clickContinue')"/>
    </div>

    <div class="svg-container">
      <svg-illustration :name="svgName"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.recco-questionnaire-intro {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  padding: var(--Spacing-spacing-7);
  align-content: center;
  justify-content: center;
  min-height: 100%;
  gap: var(--Spacing-spacing-2);

  @include screen-lg {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.welcome-text {
  display: flex;
  flex-direction: column;
  @include screen-lg {
    @include column-width(6);
  }
}

.svg-container {
  max-width: 200px;

  @include screen-lg {
    @include column-width(6);
    max-width: 350px;
  }
}

.headline {
  margin-bottom: var(--Spacing-spacing-2);
}

.caption {
  order: -1;
  margin-bottom: var(--Spacing-spacing-1);
}

.copy {
  margin-bottom: var(--Spacing-spacing-4);
}

</style>