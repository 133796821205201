<script lang="ts" setup>
import RecsRowHeadline from '@/vue/molecules/recco/recs-row/recs-row-headline.vue';
import RecsRowList from '@/vue/molecules/recco/recs-row/recs-row-list.vue';
import {useApi} from '@/ts/composables/stateful/use-api';
import {getRandomId} from '@/ts/utils/pure-functions';
import LoadingError from '@/vue/templates/loading-error.vue';
import {ref, computed, defineEmits, watchEffect} from 'vue';
import {useReccoOnboardingState} from '@/ts/composables/pure/use-recco-onboarding-state';
import type {ReccoRecommendationDTO, ReccoRecommendationResponse} from '@/ts/types/dto/recco/recommendation.dto';

const emit = defineEmits<{
  'featuredRecommendationChange': [ReccoRecommendationDTO | null];
  'ready': [];
 }>();


const sectionElement = ref<HTMLDivElement>();
const labelledById = getRandomId();
const api = useApi();

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const {syncReccoOnboardingState} = useReccoOnboardingState(); //eslint-disable-line @typescript-eslint/no-unsafe-call

const allRecommendations = ref<ReccoRecommendationResponse>();
const loading = ref<boolean>(true);
const error = ref<string>();

// Find the first recommendation in the list and emit it
const featuredRecommendation = computed(() => {
  if (!allRecommendations.value || allRecommendations.value.length === 0) {
    return null;
  }
  return allRecommendations.value[0];
});

// Get the remaining recommendations after the first one
const remainingRecommendations = computed(() => {
  if (!allRecommendations.value || allRecommendations.value.length <= 1) {
    return [];
  }
  return allRecommendations.value.slice(1);
});

// Emit the featured recommendation when it changes
watchEffect(() => {
  emit('featuredRecommendationChange', featuredRecommendation.value);
});

const fetchRecommendations = async(): Promise<void> => {
    loading.value = true;    
    
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
      const onboardingCompleted = await syncReccoOnboardingState();
      if (onboardingCompleted) {
        allRecommendations.value = await api.recco.getPreferred();
      } else {
        allRecommendations.value = await api.recco.getStarting();
      }
    } catch (err) {
      error.value = 'Failed to fetch recommendations';
    } finally {
      loading.value = false;
      emit('ready');
    }
};

defineExpose({
  refresh: () => {void fetchRecommendations();},
});

void fetchRecommendations();

</script>

<template>
  <section
    ref="sectionElement"
    :aria-labelledby="labelledById"
    aria-atomic="true"
    tabindex="-1"
  >
    <loading-error :id="labelledById" :loading="loading" :error="error" type="component">
      <div v-if="remainingRecommendations">
        <recs-row-headline :id="labelledById"/>
        <div class="row" :aria-labelledby="labelledById">
          <recs-row-list
            v-if="remainingRecommendations"
            :recommendations="remainingRecommendations"
            :labeled-by="labelledById"
          />
        </div>
      </div>
    </loading-error>
  </section>
</template>

<style lang="scss" scoped>
.row {
  min-height: 376px;
  margin-bottom: var(--Spacing-spacing-4);
}
</style>