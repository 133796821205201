<script lang="ts" setup>
import SessionToc from '@/vue/organisms/session-toc.vue';
import ContentTitleHeader from '@/vue/molecules/content-title-header.vue';
import {useInterventionStore} from '@/ts/store/intervention-store';
import {useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';
import {usePageId} from '@/ts/composables/stateful/use-page';
import SessionIntroduction from '@/vue/organisms/session-introduction.vue';
import {computed, ref, watchEffect} from 'vue';
import SessionCmsContent from '@/vue/organisms/session-cms-content.vue';
import {useSessionId} from '@/ts/composables/stateful/use-session';
import {SessionState} from '@/ts/types/dto/interventions-dto';
import SessionUnlocks from '@/vue/molecules/session-summary/session-unlocks.vue';

const intervention = useInterventionStore();

const {sid, pid} = useRouterSectionParams();
const {page} = usePageId(sid, pid);

const {inProgress: isSessionInProgress} = useSessionId(sid);

const isFirstPageInSession = computed(() => {
  return pid.value === 1;
});

const sessionIntroReady = ref<boolean>(false);

const renderFollowUpContent = computed((): boolean => {
  return !isFirstPageInSession.value || sessionIntroReady.value;
});

const {session} = useSessionId(sid);

watchEffect(() => {
  if (isFirstPageInSession.value) {
    sessionIntroReady.value = false;
  }
});

</script>

<template>
  <div v-if="intervention" class="intervention-session-page">
    <div class="toc-container">
      <session-toc/>
    </div>
    <div class="content-container">
      <content-title-header
        class="title-header"
        :title="page.name"
        :badge-pill-txt="isSessionInProgress ? $t('current') : ''"
        :badge-zeppelin-txt="$t('page_n', {n: pid})"
        :image-id="intervention.image"
        :down-sized="!isFirstPageInSession"
      />
      <session-introduction v-if="isFirstPageInSession" @ready="sessionIntroReady = true"/>
      <template v-if="renderFollowUpContent">
        <div>
          <session-unlocks :session="session"/>
        </div>
        <session-cms-content v-if="session?.state === SessionState.UNLOCK || session?.state === SessionState.COMPLETED"/>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.intervention-session-page {
  @include page-alignment;
  @include screen-lg {
    display: flex;
  }
}

.toc-container {
  margin-bottom: var(--Spacing-spacing-4);
  @include screen-lg {
    @include column-width(2);
    padding-right: var(--Spacing-spacing-1);
  }
  @include screen-xl {
    padding-right: var(--Spacing-spacing-3);
  }
}

.content-container {
  @include screen-lg {
    @include column-width(10);
    padding-left: var(--Spacing-spacing-1);
  }
  @include screen-xl {
    padding-left: var(--Spacing-spacing-3);
  }
}

.title-header {
  margin-bottom: var(--Spacing-spacing-6);
}

</style>