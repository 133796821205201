<script lang="ts" setup>
import type {ReccoRecommendationDTO} from '@/ts/types/dto/recco/recommendation.dto';
import RecommendationCardFeatured from '@/vue/molecules/recco/recommendation-cards/recommendation-card-featured.vue';

const props = defineProps<{
  recommendation: ReccoRecommendationDTO;
}>();

</script>

<template>
  <div 
    class="recco-featured-recommendation"
  >
    <h1 class="title">
      {{ $t('recco_featured_recommendation') }}
    </h1>
    <recommendation-card-featured
      :recommendation="props.recommendation"
      class="featured-card"
    />
  </div>
</template>

<style lang="scss" scoped>
.recco-featured-recommendation {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  @include column-width(12);
  @include min-featured-card-height;
  margin-bottom: var(--Spacing-spacing-4);
}

.title {
  padding-bottom: var(--Spacing-spacing-4);
}
</style>