<script setup lang="ts">
import {computed} from 'vue';

const props = defineProps<{ completed: number }>();

// 100%
const completeNumber = computed(() => Math.round(props.completed * 100));
</script>
<template>
  <div class="completed-progress">
    <div class="text">
      {{ $t('complete_n', {n: completeNumber}) }}
    </div>
    <q-linear-progress
      stripe
      rounded
      :value="completed"
      class="progress-bar"
    />
  </div>
</template>
<style scoped lang="scss">
.progress-bar {
  height: 12px;
  color: var(--Primary-primary-color-2);
  background-color: var(--Neutral-UI-neutral-ui-0);

  &:deep(.q-linear-progress__track) {
    // override quasar css to darken progress bar
    opacity: 0;
  }
}

.completed-progress {
  width: 100%;
}

.text {
  margin-bottom: var(--Spacing-spacing-2);
  @include font-body-sm-bold;
}
</style>
