<script lang="ts" setup>
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import ReccoQuestionnaireModal from '@/vue/organisms/recco/recco-questionnaire-modal.vue';
import {computed, ref, watchEffect} from 'vue';
import LoadingError from '@/vue/templates/loading-error.vue';
import {ReccoQuestionnaireCategory, type ReccoQuestionnaireType} from '@/ts/types/component/recco-questionnaire.type';
import {useNextAvailableReccoQuestionnaire} from '@/ts/composables/pure/use-next-available-recco-questionnaire';
import {useI18n} from 'vue-i18n';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';

const emit = defineEmits<{
  completeAssessment: [],
}>();

const isLoading = ref<boolean>(true);
const isNoAssessmentAvailable = ref<boolean>(false);
const {t: $t} = useI18n();

const {getNextAvailableReccoQuestionnaire} = useNextAvailableReccoQuestionnaire();

const isQuestionnaireVisible = ref<boolean>(false);
const reccoQuestionnaireType = ref<ReccoQuestionnaireType>();
let dataSyncNeeded = true;

function onClickStart(): void {
  isQuestionnaireVisible.value = true;
}

async function determineQuestionnaire(): Promise<void> {
  isLoading.value = true;
  try {
    reccoQuestionnaireType.value = await getNextAvailableReccoQuestionnaire();
    dataSyncNeeded = false;
  } catch (err) {
    reccoQuestionnaireType.value = undefined; //eslint-disable-line
    isNoAssessmentAvailable.value = true;
  } finally {
    isLoading.value = false;
  }
};

const ctaLabel = computed((): string => {
  const topic = reccoQuestionnaireType.value?.category;
  return $t('recco_assessment_cta', {topic});
});

watchEffect(() => {
  if (!isQuestionnaireVisible.value && dataSyncNeeded) {
    void determineQuestionnaire();
  }
});

const onCompleteQuestionnaire = (): void => {
  dataSyncNeeded = true;
  emit('completeAssessment');
};

const svgName = computed((): string => {
  return {
    [ReccoQuestionnaireCategory.NUTRITION]: 'topic-nutrition.svg',
    [ReccoQuestionnaireCategory.MENTAL_WELLBEING]: 'topic-mental-wellbeing.svg',
    [ReccoQuestionnaireCategory.SLEEP]: 'topic-sleep.svg',
    [ReccoQuestionnaireCategory.PHYSICAL_ACTIVITY]: 'topic-physical-activity.svg',
    [ReccoQuestionnaireCategory.ONBOARDING]: 'topic-onboarding.svg',
  }[reccoQuestionnaireType.value?.category ?? ReccoQuestionnaireCategory.ONBOARDING] ?? 'topic-onboarding.svg';
});

</script>

<template>
  <div v-if="!isNoAssessmentAvailable" class="recco-onboarding-card">
    <loading-error id="recco-questionnaire-card" :loading="isLoading" type="component">
      <div class="container">
        <div class="card-content">
          <h2 class="text-display-4 headline">
            {{ $t('recco_assessment_title') }}
          </h2>

          <div class="text-caption-sm caption">
            {{ $t('recco_assessment_caption') }}
          </div>

          <div class="text-body-sm intro-text">
            {{ $t('recco_assessment_abstract') }}
          </div>

          <balm-btn :label="ctaLabel" @click="onClickStart"/>
        </div>

        <div class="svg-container">
          <svg-illustration :name="svgName"/>
        </div>
      </div>

      <recco-questionnaire-modal
        v-model="isQuestionnaireVisible"
        :questionnaire-type="reccoQuestionnaireType!"
        @complete-questionnaire="onCompleteQuestionnaire"
      />
    </loading-error>
  </div>
</template>

<style lang="scss" scoped>

.recco-onboarding-card {
  @include q-card;
  margin-bottom: var(--Spacing-spacing-4);
  padding: var(--Spacing-spacing-4);
  min-height: 200px;
  background-color: var(--Secondary-secondary-color-2);
  @include screen-md {
    padding: var(--Spacing-spacing-10) var(--Spacing-spacing-7) var(--Spacing-spacing-10) var(--Spacing-spacing-8);
  }
}

.card-content {
  display: flex;
  flex-direction: column;
  @include screen-md {
    @include column-width(6);
  }
}

.headline {
  margin-bottom: var(--Spacing-spacing-1);
}

.intro-text {
  margin-bottom: var(--Spacing-spacing-4);
}

.caption {
  @include font-caption-sm;
  order: -1;
  margin-bottom: var(--Spacing-spacing-1);
}

.container {
  display: flex;
  justify-content: space-between;
}

.svg-container {
  display: none;
  @include screen-md {
    display: block;
    @include column-width(6);
    max-width: 300px;
  }
}


</style>