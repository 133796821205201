<script lang="ts" setup>
import {useI18n} from 'vue-i18n';
import {computed} from 'vue';

const {t: $t} = useI18n();

//TODO: Remove feedsectiontype and just get the headline key and section name key from the api
//This is a temporary solution to get the headline for the starting recommendations
const text = computed((): string => {
  return $t('recco_dashboard_recommended_for_you_topic');
});

</script>
<template>
  <h3 class="text-h3">
    {{ text }}
  </h3>
</template>

<style lang="scss" scoped>
.text-h3 {
  @include font-h3-bold;
  color: var(--Neutral-UI-neutral-ui-60);
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
</style>
