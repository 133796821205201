<script lang="ts" setup>
import type {NavigationItem} from '@/ts/types/component/navigation';
import {useScreenSize} from '@/ts/composables/stateful/use-screen-size';
import WithdrawInterventionBtn from '@/vue/organisms/withdraw-intervention-btn.vue';

const {isDesktopResolution} = useScreenSize();

const props = defineProps<{
  mainNavigationItems?: NavigationItem[];
}>();

const emit = defineEmits<{
  clickMainNavigationItem: [string],
}>();

function onClickMainNavigationItem(id?: string|null): void {
  if (id) {
    emit('clickMainNavigationItem', id);
  }
}

</script>

<template>
  <nav v-if="props.mainNavigationItems" :aria-label="$t('aria_main_navigation')" class="main-navigation">
    <q-list
      class="nav-list"
    >
      <q-item
        v-for="item in mainNavigationItems"
        :key="item.id"
        class="nav-item text-body-bold"
        :to="item.path"
        clickable
        :dense="isDesktopResolution"
        @click="onClickMainNavigationItem(item.id)"
      >
        {{ $t(item.labelKey) }}
      </q-item>
      <withdraw-intervention-btn/>
    </q-list>
  </nav>
</template>

<style lang="scss" scoped>
.nav-list {
  display: flex;
  flex-direction: column;
  @include screen-lg {
    flex-direction: row;
  }
}

.nav-item {
  align-items: center;
  @include focus-visible;
}
</style>