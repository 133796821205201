<script setup lang="ts">
import {pagesPath} from '@/ts/router/pages-path';
import DrawerOnMobile from '@/vue/molecules/drawer-on-mobile.vue';
import {useDrawerStore} from '@/ts/store/drawer-store';
import {storeToRefs} from 'pinia';
import UserAvatar from '@/vue/molecules/user-avatar.vue';
import FlatIconBtn from '@/vue/molecules/flat-icon-btn.vue';
import LanguageSwitcher from '@/vue/molecules/language-switcher.vue';
import {useAlert} from '@/ts/composables/pure/use-alert';
import {persistentStorage} from '@/ts/instances/persistant-storage';
import {ZITADEL} from '@/ts/utils/consts';
import {useApi} from '@/ts/composables/stateful/use-api';
import {useI18n} from 'vue-i18n';

const emit = defineEmits<{
  requestOpenerFocus: [],
}>();

const drawerStore = useDrawerStore();
const {isUserDrawerOpen} = storeToRefs(drawerStore);

function close(): void {
  drawerStore.closeUserDrawer();
  emit('requestOpenerFocus');
}

const {t: $t} = useI18n();
const api = useApi();
const {showConfirmDialog} = useAlert();

async function showSignOut(): Promise<void> {
  drawerStore.closeUserDrawer();
  try {
    await showConfirmDialog($t('you_are_about'), {
      okLabel: $t('sign_out'),
    });
    const idToken = persistentStorage.auth!.id_token;
    persistentStorage.auth = null;
    api.zitadel.revokeToken(idToken, ZITADEL.client_id, `${window.location.origin}/`);
  } catch {/* user clicked cancel */}
}

</script>

<template>
  <drawer-on-mobile :is-open="isUserDrawerOpen" :align-right="true" @swipe-or-esc-to-close="close">
    <div class="menu" :class="{'menu--is-closed': !isUserDrawerOpen}">
      <div class="mobile-only-drawer-header">
        <user-avatar/>
        <flat-icon-btn
          icon="x-close.svg"
          :label="$t('aria_close_user_menu')"
          @click="close"
        />
      </div>
      <q-list class="list">
        <q-item
          clickable
          :to="pagesPath.account.changePassword"
          class="list-item text-body-bold"
        >
          {{ $t('change_password') }}
        </q-item>
        <language-switcher class="list-item" :display-as-list-item="true"/>
        <div class="separator"/>
        <q-item clickable class="list-item text-body" @click="showSignOut">
          {{ $t('sign_out') }}
        </q-item>
      </q-list>
    </div>
  </drawer-on-mobile>
</template>
<style lang="scss" scoped>
.menu {
  position: relative;
  z-index: 1;

  &--is-closed {
    display: none;
    @include screen-lg {
      display: block;
    }
  }
}

.mobile-only-drawer-header {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 0 var(--Spacing-spacing-3);
  margin-bottom: var(--Spacing-spacing-4);

  @include screen-lg {
    display: none;
  }
}

.list-item {
  align-items: center;
  @include focus-visible;

  &:focus-visible {
    @include screen-lg {
      outline-offset: -4px;
    }
  }
}

.separator {
  position: relative;
  margin: var(--Spacing-spacing-4) 0;
  height: 1px;

  @include screen-lg {
    display: none;
  }

  &:after {
    content: "";
    border-top: 1px solid var(--Neutral-UI-neutral-ui-30);
    top: 0;
    left: var(--Spacing-spacing-3);
    right: var(--Spacing-spacing-3);
    position: absolute;
  }
}
</style>
