<script lang="ts" setup>
import {computed, ref, onMounted, onBeforeUnmount, nextTick} from 'vue';
import LazyImage from '@/vue/atoms/lazy-image.vue';
import ReccoMediaTypeAndDuration from '@/vue/molecules/recco/recco-media-type-and-duration.vue';
import {pagesPath} from '@/ts/router/pages-path';
import type {RouteLocationNamedRaw} from 'vue-router';
import {type ReccoRecommendationDTO, ReccoRecommendationTypeDTO} from '@/ts/types/dto/recco/recommendation.dto';
import type {ReccoArticleDTO} from '@/ts/types/dto/recco/article.dto';
import {useProvidedRecommendation} from '@/ts/composables/pure/use-provided-recommendation';
import {useLogger} from '@/ts/composables/pure/use-logger';
import {useI18n} from 'vue-i18n';

const logger = useLogger('RecommendationCardFeatured');
const {t: $t} = useI18n();

const props = defineProps<{
  recommendation: ReccoRecommendationDTO;
}>();

//Get the underlying recommendation data based on the type of recommendation
const {currentRecommendation, fetchRecommendation, recommendationType} = useProvidedRecommendation(props.recommendation);

//The type of recommendation will determine the available fields to render. 
//For example, an article will have a lead field, but a video will not.
//The type of recommendation will also determine the link to the recommendation.

const article = computed(() => recommendationType.value === ReccoRecommendationTypeDTO.Article 
  ? currentRecommendation.value as ReccoArticleDTO 
  : null);

//TODO: Add video and audio when supported

const idAria = computed(() => `recommendation_${props.recommendation?.id.catalogId}`);

const recLink = computed((): RouteLocationNamedRaw|null => {
  const routeName = pagesPath.main.recommendation;
  return {
    name: routeName,
    params: {
      itemId: props.recommendation.id.itemId,
      catalogId: props.recommendation.id.catalogId,
      contentType: props.recommendation.type,
    },
  };
});

const cardRef = ref<HTMLElement | null>(null);
const imageWidth = computed(() => {
  if (cardRef.value?.offsetWidth) {
    // Round to nearest 100px
    return Math.round(cardRef.value.offsetWidth / 100) * 100;
  }
  return 0;
});

// Update width on resize
let resizeObserver: ResizeObserver | null = null;

async function setupResizeObserver(): Promise<void> {
  // Wait for DOM update
  await nextTick();
  
  if (window.ResizeObserver && cardRef.value instanceof Element) {
    resizeObserver = new ResizeObserver(() => {
      // The computed will automatically update when cardRef.value.offsetWidth changes
    });
    resizeObserver.observe(cardRef.value);
  }
};

onMounted(async() => {
  await setupResizeObserver();
  logger.debug('Component mounted, initiating fetch')();
  fetchRecommendation().catch((error: unknown) => {
    logger.error('Unhandled error in fetchRecommendation', {error})();
  });
  logger.debug('Setting up recommendation response', { 
    hasCurrentRecommendation: Boolean(currentRecommendation.value),
  })();
});

onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.disconnect();
    resizeObserver = null;
  }
});
</script>

<template>
  <div ref="cardRef" class="recommendation-card-featured">
    <router-link
      v-if="recLink"
      :to="recLink"
      class="rec-link"
      :aria-labelledby="idAria"
      :recommendation="recommendation"
    >
      <lazy-image
        format="webp"
        :src="recommendation.dynamicImageResizingUrl ?? ''"
        :width="imageWidth ?? undefined"
        class="img"
        loading="lazy"
        :alt="recommendation.imageAlt"
      />
      <q-card-section class="card-section">
        <div class="description-content-container">
          <recco-media-type-and-duration class="media-type" :type="recommendation.type" size="s"/>
          <div class="title-box">
            <span :id="idAria" class="title">
              {{ recommendation.headline }}
            </span>
          </div>
          <!-- Article specific content -->
          <div class="content">
            <div v-if="recommendationType === ReccoRecommendationTypeDTO.Article && article">
              <p v-if="article.lead" class="description">
                {{ article.lead }}
              </p>
            </div>
          </div>
        </div>
        <div class="call-to-action">
          <span class="call-to-action-label">
            {{ $t('recco_article_read_more') }}
          </span>
          <q-icon name="arrow_forward"/>
        </div>
      </q-card-section>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.recommendation-card-featured {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.rec-link {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include focus-visible;
  border-radius: var(--card-border-radius);
  gap: var(--Spacing-spacing-1);

  @include screen-md {
    flex-direction: row;
    gap: var(--Spacing-spacing-4);
  }
}

.img {
  max-height: 350px;
  @include min-featured-card-height;

  
  :deep(.q-img__container) {
    border-radius: var(--card-border-radius);
  }

  :deep(.q-skeleton) {
    border-radius: var(--card-border-radius);
  }

  @include screen-md {
    @include column-width(7, 10);
    
  }
}

.card-section {
  @include q-card;
  @include min-featured-card-height;
  max-height: 350px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--Spacing-spacing-8) var(--Spacing-spacing-7) var(--Spacing-spacing-7) var(--Spacing-spacing-6);
  background-color: #CCC8C1;

  @include screen-md {
    @include column-width(3, 10);
    
  }
}


.description-content-container {
  display: flex;
  flex-direction: column;
}

.media-type {
  :deep(.media-type-label) {
    @include font-caption;
  }
  padding-bottom: var(--Spacing-spacing-3);
}

.title-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--Spacing-spacing-4);
}

.title {
  @include font-h3;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--Spacing-spacing-4);
}

.description {
  @include font-body;
  font-weight: 500;
  color: var(--Neutral-UI-neutral-ui-70);
}

.call-to-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--Spacing-spacing-4);
}

.call-to-action-label {
  @include font-body;
  color: var(--Neutral-UI-neutral-ui-100);
  font-weight: 700;
}
</style>
