<script lang="ts" setup>
import BadgePill from '@/vue/atoms/badge-pill.vue';

defineProps<{
  theme: 'blue' | 'white' | 'green' | 'red'
}>();
</script>

<template>
  <badge-pill :theme="theme" :label="$t('current')"/>
</template>
