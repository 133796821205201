import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import type {ReccoTopicDTO} from '@/ts/types/dto/recco/topic.dto';

export interface ReccoNextAvailableQuestionnaireResponse {
  topic: ReccoTopicDTO;
  questionnaire:   ReccoQuestionDTO[];
}

export type ReccoQuestionnaireResponse = ReccoQuestionDTO[];

export interface ReccoQuestionDTO {
  contentId: UuidDTO;
  reccoQuestionnaireId: string;
  reccoQuestionId: string;
  type: ReccoQuestionAnswerType;
  name: string;
  isAnswered: boolean;
  multiChoice?: ReccoMultiChoiceQuestionDTO;
  multiChoiceSelectedIds?: number[];
  numeric?: ReccoNumericQuestionDTO;
  numericSelected?: number;
}

export interface ReccoMultiChoiceQuestionDTO {
  maxOptions: number;
  minOptions: number;
  options: ReccoMultiChoiceAnswerOptionDTO[];
}

export interface ReccoMultiChoiceAnswerOptionDTO {
  id: UuidDTO,
  reccoId: number,
  text: string,
  value: number,
  defaultAnswer: boolean,
  showValue: boolean,
}

export interface ReccoNumericQuestionDTO {
  maxValue: number;
  minValue: number;
  format: ReccoNumericQuestionFormat;
}

export interface ReccoCreateQuestionnaireAnswerDTO {
  reccoQuestionnaireId: string;
  reccoQuestionId: string;
  answers: {
    multiChoiceRecco?: number[];
    numeric?: number;
    type: ReccoQuestionAnswerType;
  }
}

// backend enum QuestionAnswerType
export enum ReccoQuestionAnswerType {
  MultiChoice = 'multichoice',
  Numeric = 'numeric',
}

// backend class Format
export enum ReccoNumericQuestionFormat {
  HumanHeight = 'human_height', // stored in cm
  HumanWeight = 'human_weight', // stored in kg
  Integer = 'integer',
  Decimal = 'decimal',
}
