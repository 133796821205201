<script lang="ts" setup async>
import {type ComponentPublicInstance, computed, onMounted, onBeforeUnmount, ref, nextTick} from 'vue';
import LazyImage from '@/vue/atoms/lazy-image.vue';
import type {ReccoArticleDTO} from '@/ts/types/dto/recco/article.dto';

const props = defineProps<{
  article: ReccoArticleDTO;
}>();

const {article} = props;

const imageContainer = ref<ComponentPublicInstance<typeof LazyImage> | null>(null);

const imageWidth = computed(() => {
  const element = imageContainer.value?.$el as HTMLElement;
  if (element?.offsetWidth) {
    // Round to nearest 100px
    return Math.round(element.offsetWidth / 100) * 100;
  }
  return 0;
});

// Update width on resize
let resizeObserver: ResizeObserver | null = null;

async function setupResizeObserver(): Promise<void> {
  await nextTick();
  
  const element = imageContainer.value?.$el as Element;
  if (window.ResizeObserver && article.dynamicImageResizingUrl && element instanceof Element) {
    resizeObserver = new ResizeObserver(() => {
      // The computed will automatically update when container width changes
    });
    resizeObserver.observe(element);
  }
}

onMounted(async() => {
  await setupResizeObserver();
});

onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.disconnect();
    resizeObserver = null;
  }
});

</script>
<template>
  <div class="article-container">
    <lazy-image
      ref="imageContainer"
      :width="imageWidth"
      format="webp"
      :src="article.dynamicImageResizingUrl ?? ''"
      loading="eager"
      :alt="article.imageAlt"
      class="image"
    />
    <div class="article-text-container">
      <h1 class="article-headline">
        {{ article.headline }}
      </h1>

      <p v-if="article.lead" class="article-lead">
        {{ article.lead }}
      </p>

      <p data-playwright="content" class="article-body" v-html="article.articleBodyHtml"/> <!--eslint-disable-line vue/no-v-html-->
    </div>
  </div>
</template>
<style lang="scss" scoped>
.article-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-spacing-4);
  padding-bottom: var(--Spacing-spacing-7);
}

.article-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--Spacing-spacing-7) var(--Spacing-spacing-8) var(--Spacing-spacing-8) var(--Spacing-spacing-6);
  gap: var(--Spacing-spacing-4);
  flex-shrink: 0;
  
  @include screen-2xl {
    @include column-width(6, 10);
  }

  @include screen-xl {
    @include column-width(9, 10);
  }

  @include screen-lg {
    @include column-width(8, 10);
  }

  @include screen-md {
    @include column-width(8, 10);
  }  
}

.article-headline {
  @include font-h1;
}

.article-lead {
  @include font-h3-bold;
  margin-bottom: 0;
}

.article-body {
  @include font-body;
}

.image {
  max-height: 500px;
  border-radius: var(--card-border-radius);

  @include screen-sm {
    min-height: 300px;
  }

  @include screen-md {
    min-height: 500px;
  }
}




</style>
