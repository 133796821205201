<script lang="ts" setup>
import {useApi} from '@/ts/composables/stateful/use-api';
import {useLoadingNotification} from '@/ts/composables/pure/use-loading-notification';
import {useRouter} from 'vue-router';
import {useInterventionLinks} from '@/ts/composables/stateful/use-links';
import {useIntervention} from '@/ts/composables/stateful/use-intervention';
import {useInterventionStore} from '@/ts/store/intervention-store';
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import {PublicationState} from '@/ts/types/dto/interventions-dto';
import NoClickBtn from '@/vue/molecules/no-click-btn.vue';
import BackLink from '@/vue/molecules/back-link.vue';
import InterventionLabel from '@/vue/molecules/intervention-label.vue';

const intervention = useInterventionStore();

const {hasContent} = useIntervention()!;
const api = useApi();
const router = useRouter();
const {aboutLink, sessionsLink, currentSessionLink, recapLink} = useInterventionLinks();
const [enroll, enrollingInProgress] = useLoadingNotification(
  async() => api.me.enrollIntervention(intervention.value!.id),
  (newIntervention) => {
    intervention.value = newIntervention;
    void router.push(currentSessionLink.value!);
  },
);
</script>
<template>
  <div class="intervention-details-page">
    <div class="header">
      <q-tabs class="tabs">
        <q-route-tab :to="aboutLink" :label="$t('about')"/>
        <q-route-tab v-if="hasContent" :to="sessionsLink" :label="$t('sessions')"/>
        <q-route-tab v-if="intervention!.publicationState === PublicationState.COMPLETED" :to="recapLink" :label="$t('recap')"/>
      </q-tabs>
    </div>

    <div class="menu">
      <intervention-label class="label" :publication-state="intervention!.publicationState"/>
      <back-link :label="intervention!.name"/>
      <div>
        {{ intervention!.description }}
      </div>
      <div class="q-mt-auto"/>
      <no-click-btn
        v-if="[PublicationState.COMPLETED, PublicationState.IN_PROGRESS].includes(intervention!.publicationState!)"
        class="registered"
        icon="check.svg"
        theme="p3-7"
        :title="intervention!.publicationState === PublicationState.IN_PROGRESS ? $t('registered') : $t('completed')"
      />
      <balm-btn
        v-else-if="hasContent"
        paddings="p3-7"
        theme="blue"
        class="enroll-btn"
        :label="$t('enroll')"
        :loading="enrollingInProgress"
        :disable="enrollingInProgress"
        @click="enroll"
      />
    </div>

    <div class="main">
      <router-view/>
    </div>
  </div>
</template>
<style lang="scss" scoped>

.tabs {
  @include tabs;
}

.intervention-details-page {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 1fr 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
  "menu"
  "header"
  "main";

  @include screen-md {
    grid-template-columns: $menu-width 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
    "menu header"
    "menu main";
  }

  > .menu {
    height: 100%;
    grid-area: menu;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    @include screen-md {
      overflow-y: auto;
      padding: var(--Spacing-spacing-6) var(--Spacing-spacing-6) var(--Spacing-spacing-6) var(--Spacing-spacing-5);
    }
  }

  > .header {
    grid-area: header;
    padding: var(--Spacing-spacing-6) 0 var(--Spacing-spacing-2) 0;
    display: flex;

    @include screen-md {
      padding: var(--Spacing-spacing-4);
    }
  }

  > .main {
    grid-area: main;
    padding: var(--Spacing-spacing-2) 0;

    @include screen-md {
      overflow-y: auto;
      padding: var(--Spacing-spacing-6) var(--Spacing-spacing-7) var(--Spacing-spacing-7) var(--Spacing-spacing-4);
    }
  }
}

.enroll-btn {
  flex-shrink: 0; // if narrow screen height it will consume space from button

  @include screen-md {
    width: 100%;
  }
}

.registered {
  @include screen-md {
    margin: 0 auto;
    width: 100%;
  }
}

.menu {
  gap: var(--Spacing-spacing-2);

  @include screen-md {
    gap: var(--Spacing-spacing-6);
  }
}

.header {
  gap: var(--Spacing-spacing-4);
  justify-content: space-between;
}

</style>
