export enum ReccoQuestionnaireCategory {
  ONBOARDING = 'onboarding',
  PHYSICAL_ACTIVITY = 'physical_activity',
  NUTRITION = 'nutrition',
  MENTAL_WELLBEING = 'mental_wellbeing',
  SLEEP = 'sleep',
}

export interface ReccoQuestionnaireType {
  id?: string;
  category: ReccoQuestionnaireCategory,
}