<script lang="ts" setup>
import {PublicationState} from '@/ts/types/dto/interventions-dto';
import BadgePill from '@/vue/atoms/badge-pill.vue';
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';

const {t: $t} = useI18n();

const props = defineProps<{
  publicationState: PublicationState
}>();

const theme = computed(() => {
  if (props.publicationState === PublicationState.IN_PROGRESS) {
    return 'blue';
  }
  if (props.publicationState === PublicationState.NEW || props.publicationState === PublicationState.COMPLETED) {
    return 'green';
  }
  if (props.publicationState === PublicationState.MAINTENANCE) {
    return 'red';
  }
  return 'white';
});

const label = computed(() => {
  if (props.publicationState === PublicationState.IN_PROGRESS) {
    return $t('enrolled');
  }
  if (props.publicationState === PublicationState.COMPLETED) {
    return $t('completed');
  }
  if (props.publicationState === PublicationState.MAINTENANCE) {
    return $t('maintenance');
  }
  if (props.publicationState === PublicationState.NEW) {
    return $t('new');
  }
  return '';
});

</script>

<template>
  <badge-pill v-if="label" :theme="theme" :label="label"/>
</template>
