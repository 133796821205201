<script lang="ts" setup>
import {
  onBeforeMount,
  ref,
  watch,
} from 'vue';
import {useI18n} from 'vue-i18n';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import type {PrimaryFeeling} from '@/ts/types/dto/interventions-dto';
import FeelingInput from '@/vue/molecules/components/helpers/feeling-input.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';
import BalmDialog from '@/vue/atoms/balm-dialog.vue';

const props = defineProps<{
  feelings: PrimaryFeeling[];
}>();
const model = defineModel<UuidDTO[]>('ids');
const isOpen = defineModel<boolean>('isOpen');

// array of array is required for 2 level loop which is flattened in model
const value = ref<UuidDTO[][]>([]);

const logger = useLogger();
const {t: $t} = useI18n();


function getCurrentArrayValue(val: UuidDTO[]): UuidDTO[][] {
  // fill with 2nd level feelings
  const res: UuidDTO[][] = props.feelings.map((feeling) => feeling.secondaryFeelings.filter(sf => val.includes(sf.id)).map(sf => sf.id));
  // add first level feelings
  props.feelings.forEach((feeling, int) => {
    if (val.includes(feeling.id)) {
      res[int].push(feeling.id);
    }
  });
  return res;
}

onBeforeMount(() => {
  value.value = getCurrentArrayValue(model.value!);
  logger.debug('setting value to {}', value.value)();
});

watch(value, (newVal) => {
  logger.debug('value changed', newVal)();
  // prevent infinitive watch from below
  if (newVal.reduce((totalLength, currentItem) => totalLength + currentItem.length, 0) !== model.value!.length) {
    model.value = newVal.flat();
    logger.debug('updating model', model.value)();
  }
}, {deep: true});


watch(model, (newModelValue) => {
  logger.debug('model changed', newModelValue)();
  // prevent infinitive watch from above
  if (newModelValue!.length !== value.value.reduce((totalLength, currentItem) => totalLength + currentItem.length, 0)) {
    value.value = getCurrentArrayValue(newModelValue!);
    logger.debug('updating value', value.value)();
  }
}, {deep: true});

</script>

<template>
  <balm-dialog v-model="isOpen" type="fit-content">
    <h2 class="text-body-bold">
      {{ $t('select_what_you') }}
    </h2>
    <div class="feelings">
      <feeling-input v-for="(feeling, i) in feelings" :key="feeling.id" v-model="value[i]" :feeling="feeling"/>
    </div>
  </balm-dialog>
</template>

<style scoped lang="scss">
.feelings {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-4);
  margin: var(--Spacing-spacing-4) 0;
  @include screen-lg {
    width: 700px;
  }
}
</style>
