import {useApi} from '@/ts/composables/stateful/use-api';
import {useReccoOnboardingState} from '@/ts/composables/pure/use-recco-onboarding-state';
import {ReccoQuestionnaireCategory, type ReccoQuestionnaireType} from '@/ts/types/component/recco-questionnaire.type';

//eslint-disable-next-line
export function useNextAvailableReccoQuestionnaire() {
  const api = useApi();
  const {syncReccoOnboardingState} = useReccoOnboardingState();

  const getNextAvailableReccoQuestionnaire = async(): Promise<ReccoQuestionnaireType> => {
    const isOnboardingCompleted = await syncReccoOnboardingState();

    if (isOnboardingCompleted) {
      const nextQuestionnaireResponse = await api.recco.getNextAvailableQuestionnaire();
      if (!nextQuestionnaireResponse) {
        throw Error('No questionnaire available');
      }
      const questionnaireId = nextQuestionnaireResponse?.questionnaire[0]?.reccoQuestionnaireId;
      return {
        id: questionnaireId,
        category: nextQuestionnaireResponse?.topic.toString() as ReccoQuestionnaireCategory,
      };
    }

    return {
      category: ReccoQuestionnaireCategory.ONBOARDING,
    };
  };

  return {
    getNextAvailableReccoQuestionnaire,
  };
}