<script lang="ts" setup>
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {
  computed,
  onBeforeMount,
} from 'vue';
import type {
  Option,
  QuestionMultiChoice,
} from '@/ts/types/dto/interventions-dto';
import {useLogger} from '@/ts/composables/pure/use-logger';
import FormRequired from '@/vue/atoms/form-required.vue';
import AriaListBox from '@/vue/molecules/aria-list-box.vue';
import type {ListBoxOption} from '@/ts/types/component/aria-list-box.type';
import AnswerOptionChip from '@/vue/molecules/answer-option-chip.vue';
import AnswerOptionDefault from '@/vue/molecules/answer-option-default.vue';
import BadgePill from '@/vue/atoms/badge-pill.vue';

const model = defineModel<UuidDTO[]>();
const props = defineProps<{
  question: QuestionMultiChoice;
  theme: 'chips' | 'list-question-in-col' | 'list-question-in-row';
}>();
const logger = useLogger();
const emit = defineEmits<{
  readyToGoNext: [],
}>();

const options = computed<Option[]>(() => props.question.multiChoice.options);

const isSingleChoice = computed(() => {
  return props.question.multiChoice.maxOptions === 1;
});

onBeforeMount(() => {
  logger.debug(`onBeforeMount ${props.question.contentId}`)();
  if (model.value && model.value.length > 0) {
    logger.trace(`Value of ${props.question.contentId} already set`)();
  } else if (props.question.multiChoiceSelectedIds) {
    logger.trace(`emit ${props.question.contentId} => ${props.question.multiChoiceSelectedIds.join(',')}`)();
    model.value = props.question.multiChoiceSelectedIds;
  } else if (!model.value) {
    logger.trace(`emit ${props.question.contentId} => []`)();
    model.value = [];
  }
});

const isValid = computed(() => {
  return (model.value?.length ?? 0) >= props.question.multiChoice.minOptions
    && (model.value?.length ?? 0) <= props.question.multiChoice.maxOptions;
});

const modelValue = computed({
  get():string[] {
    return model.value as string[];
  },
  set(val: string[]): void {
    model.value = val as UuidDTO[];
    if (isSingleChoice.value && val.length > 0) {
      emit('readyToGoNext');
    }
  },
});

const listBoxOptions = computed((): ListBoxOption[] => {
  return options.value.map((option): ListBoxOption => {
    return {
      id: option.id as string,
      text: option.text,
    };
  });
});

const listLabeledById = computed(() => {
  return `question-${crypto.randomUUID()}`;
});

</script>
<template>
  <div class="question-grid-select">
    <div :class="theme">
      <badge-pill v-if="theme === 'list-question-in-row'" theme="blue" :label="$t('question')"/>
      <div :id="listLabeledById" class="question" v-html="question.name"/>

      <div class="answer">
        <aria-list-box
          v-model="modelValue"
          :options="listBoxOptions"
          :labeled-by="listLabeledById"
          :disable-default-focus-visible="true"
          :orientation="(theme === 'list-question-in-col' || theme === 'list-question-in-row') ? 'vertical' : 'ambiguous'"
          :maximum-selectable="question.multiChoice.maxOptions"
          class="list-box"
          :class="{
            'list-box--chips': theme === 'chips',
            'list-box--list': (theme === 'list-question-in-col' || theme === 'list-question-in-row'),
          }"
        >
          <template #item="templateProps">
            <answer-option-chip
              v-if="theme === 'chips'"
              :label="templateProps.option.text"
              :is-selected="templateProps.isSelected"
              :is-active="templateProps.isActive"
              :mood="'neutral'"
            />
            <answer-option-default
              v-else
              :label="templateProps.option.text"
              :is-selected="templateProps.isSelected"
              :is-active="templateProps.isActive"
            />
          </template>
        </aria-list-box>
        <form-required
          :id="`question-grid-${question.contentId}`"
          :valid="isValid"
          :message="$t('answer_questionnaire')"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@mixin question-grid-select-broad {
  @container question-grid-select (min-width: 599px) {
    @content;
  }
}

.question-grid-select {
  container-type: inline-size;
  container-name: question-grid-select;
}

.question {
  flex: 1;
  margin-bottom: var(--Spacing-spacing-5);
  @include font-h3-bold;
}

.answer {
  flex: 1;
}

.list-box {
  display: flex;
  gap: var(--Spacing-spacing-2);
  padding: var(--Spacing-spacing-2) 0;

  &--chips {
    flex-wrap: wrap;
  }

  &--list {
    flex-direction: column;
  }
}

.list-question-in-col {
  display: flex;
  flex-direction: column;

  @include question-grid-select-broad {
    flex-direction: row;
  }

  .question {
    padding: 0 var(--Spacing-spacing-6) 0 0;
    margin-bottom: var(--Spacing-spacing-2);
    @include font-body; // TODO correct this when typography is done

    @include question-grid-select-broad {
      align-self: center;
      margin-bottom: var(--Spacing-spacing-5);
      @include font-h2;
    }
  }
}

.list-question-in-row {
  display: flex;
  flex-direction: column;
  align-items: center;

  .question {
    margin: var(--Spacing-spacing-2);
    max-width: 800px;
    text-align: center;
  }

  .answer {
    min-width: 100%;

    @include screen-sm {
      min-width: 400px;
    }
  }
}

</style>
