<script lang="ts" setup>
import type {Solution} from '@/ts/types/dto/interventions-dto';
import ReadOnlyNonCollapsable from '@/vue/molecules/read-only/helpers/read-only-non-collapsable.vue';
import ListItems from '@/vue/molecules/read-only/helpers/list-items.vue';

defineProps<{
  solution: Solution;
  index: number;
}>();
</script>

<template>
  <read-only-non-collapsable active>
    <span class="solution-number">{{ $t('solution') }} {{ index }}</span>
    <div class="solution-name">
      {{ solution.name }}
    </div>
    <div class="solution-things-about">
      {{ $t('good_things_about') }}
    </div>
    <div>{{ solution.goodThings }}</div>
    <div class="solution-things-about">
      {{ $t('bad_things_about') }}
    </div>
    <div>{{ solution.badThings }}</div>
    <div v-if="solution.activities.length > 0" class="solution-things-about">
      {{ $t('possible_activities') }}
    </div>
    <list-items v-if="solution.activities.length > 0" :items="solution.activities"/>
  </read-only-non-collapsable>
</template>
<style lang="scss" scoped>
.solution-number {
  @include font-body-sm-bold;
}

.solution-name {
  margin-top: var(--Spacing-spacing-3);
}

.solution-things-about {
  margin-top: var(--Spacing-spacing-4);
  margin-bottom: var(--Spacing-spacing-1);
  @include font-body-sm;
  font-weight: bold;
}
</style>


