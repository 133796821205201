<script setup lang="ts">
import {useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';
import {LayoutGoalDisplayType, SessionState} from '@/ts/types/dto/interventions-dto';
import {computed, ref, watch} from 'vue';
import HomeworkModal from '@/vue/organisms/homework-modal.vue';
import {useInterventionStore} from '@/ts/store/intervention-store';
import {useLoadingDataRefNull} from '@/ts/composables/pure/use-loading-data-ref';
import LoadingError from '@/vue/templates/loading-error.vue';
import {useApi} from '@/ts/composables/stateful/use-api';
import LazyImage from '@/vue/atoms/lazy-image.vue';
import GoalShort from '@/vue/molecules/goal-short.vue';
import SessionHomeworkLink from '@/vue/molecules/session-summary/session-homework-link.vue';

const emit = defineEmits<{
  ready: [],
}>();

const {iid, sid} = useRouterSectionParams();
const intervention = useInterventionStore();

const api = useApi();
const [session, loading, error] = useLoadingDataRefNull(async() => api.me.getSession(iid.value, sid.value));

const homeworkAvailable = computed(() => session.value?.state === SessionState.HOMEWORK_PENDING);
const homeWorkOpen = ref(false);

watch(loading, (loadingVal) => {
  if (!loadingVal) {
    emit('ready');
  }
});

</script>
<template>
  <loading-error id="session-introduction" :loading="loading" :error="error" type="component">
    <div class="session-introduction">
      <div class="summary">
        <div class="summary__text">
          <h2 class="headline">
            {{ $t('summary') }}
          </h2>
          <div v-html="session!.description"/>
        </div>
        <div class="summary__media">
          <lazy-image v-if="session!.image" :src="session!.image" :width="1200" class="image"/>
        </div>
      </div>

      <div v-if="session!.goals.length" class="goals-card">
        <div class="goal">
          <h2>{{ $t('session_goals') }}</h2>
          <div class="goals">
            <goal-short
              v-for="(goal, i) in session!.goals"
              :key="goal.id"
              :order="i + 1"
              :goal="goal"
              :finished="goal.displayType === LayoutGoalDisplayType.COMPLETE"
            />
          </div>
        </div>
      </div>
      <homework-modal v-if="homeworkAvailable" v-model="homeWorkOpen"/>
      <session-homework-link
        v-if="homeworkAvailable && intervention && session"
        :intervention="intervention"
        :session-id="session?.id"
        @open-homework="homeWorkOpen = true"
      />
    </div>
  </loading-error>
</template>

<style scoped lang="scss">
.session-introduction {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-4);
  margin-bottom: var(--Spacing-spacing-4);
  @include screen-lg {
    padding-bottom: var(--Spacing-spacing-7);
  }
}

.summary {
  display: flex;
  flex-direction: column-reverse;
  gap: var(--Spacing-spacing-4);

  @include screen-lg {
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 0;
  }

  &__text {
    @include screen-lg {
      @include column-width(6, 10);
      padding: var(--Spacing-spacing-7) 0 0 var(--Spacing-spacing-3);

    }
  }

  &__media {
    height: 450px;
    @include screen-lg {
      @include column-width(3, 10);
      padding-right: var(--Spacing-spacing-3);
    }
  }
}

.headline {
  @include font-h3-bold;
  margin-bottom: var(--Spacing-spacing-4);
}

.image {
  border-radius: var(--card-border-radius);
  overflow: hidden;
  height: 100%;
}

.goals {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--Spacing-spacing-4);
  container-type: inline-size;
}

.goals-card {
  @include q-card-0;
  gap: var(--Spacing-spacing-6);
  padding: var(--Spacing-spacing-4);
  @include screen-lg {
    padding: var(--Spacing-spacing-7);
  }
}
</style>
