<script lang="ts" setup>
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import BadgePill from '@/vue/atoms/badge-pill.vue';
import BadgeZeppelin from '@/vue/atoms/badge-zeppelin.vue';
import LazyImage from '@/vue/atoms/lazy-image.vue';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';

defineProps<{
  title: string;
  badgeZeppelinTxt?: string;
  badgePillTxt?: string;
  downSized?: boolean;
  imageId?: UuidDTO;
}>();

</script>

<template>
  <div class="content-title-header" :class="{'content-title-header--down-sized': downSized}">
    <div class="bottom">
      <div class="title-row">
        <h1>{{ title }}</h1>
        <svg-illustration name="right-down-arrow.svg" inner-class="inner-right"/>
      </div>
      <div v-if="badgeZeppelinTxt">
        <badge-zeppelin :label="badgeZeppelinTxt"/>
      </div>
    </div>

    <div v-if="imageId" class="image-aligner" :class="{'image-aligner--pale': downSized}">
      <lazy-image :src="imageId" class="image"/>
    </div>

    <div class="top">
      <badge-pill v-if="badgePillTxt" theme="white" :label="badgePillTxt"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.content-title-header {
  @include q-card-blue;
  padding: var(--Spacing-spacing-7) var(--Spacing-spacing-5) var(--Spacing-spacing-6) var(--Spacing-spacing-5);
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  position: relative;
  min-height: 250px;
  overflow: hidden;

  @include screen-md {
    min-height: 416px;
  }


  &--down-sized {
    min-height: 170px;
    @include screen-md {
      min-height: 300px;
    }
  }
}

.title-row {
  display: flex;
  justify-content: space-between;
}

.top {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}

.bottom {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;
  gap: var(--Spacing-spacing-1);
}

.image-aligner {
  @include screen-lg {
    position: absolute;
    top: -5%;
    left: 15%;
    width: 85%;
    height: 105%;
  }

  &--pale {
    opacity: 0.2;
    position: absolute;
    top: -5%;
    left: -5%;
    right: -5%;
    bottom: -5%;
    width: auto;
    height: auto;
  }
}

.image {
  height: 100%;
}


</style>