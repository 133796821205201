<script lang="ts" setup>

import ReadOnlyNonCollapsable from '@/vue/molecules/read-only/helpers/read-only-non-collapsable.vue';

defineProps<{
  active: boolean;
  header?: string;
  content?: string;
  content2?: string;
}>();

defineSlots<{
  default?: any;
}>();
</script>

<template>
  <read-only-non-collapsable :active="active">
    <div v-if="header" class="ro-non-coll-title">
      {{ header }}
    </div>
    <div v-if="$slots.default">
      <slot/>
    </div>
    <div v-if="content" v-html="content"/>
    <div v-if="content2" v-html="content2"/>
  </read-only-non-collapsable>
</template>
<style lang="scss" scoped>
.ro-non-coll-title {
  @include font-body-bold;
  margin-bottom: var(--Spacing-spacing-4);
}
</style>
