import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';

export type GetInterventionsResponse = InterventionPreview[]
export type GetInterventionResponse = Intervention;

export enum PublicationState {
  IN_PROGRESS = 'IN_PROGRESS',
  WITHDREW = 'WITHDREW',
  COMPLETED = 'COMPLETED',
  NOT_JOINED = 'NOT_JOINED',
  ACTIVE = 'ACTIVE',
  NEW = 'NEW',
  MAINTENANCE = 'MAINTENANCE',
  DISCONTINUED = 'DISCONTINUED',
  PREVIEW = 'PREVIEW',
  PUBLISHED = 'PUBLISHED',
}

export interface ThinkingTrap {
  id: UuidDTO;
  name: string;
  description?: string;
  example: string;
}

export interface InterventionPreview {
  completionProgress: number | null;
  description: string;
  id: UuidDTO;
  name: string;
  indication: Indication
  image: UuidDTO | null;
  // TODO rename to state when BE is ready
  publicationState: PublicationState;
}

export interface Intervention {
  id: UuidDTO
  name: string
  description: string
  sessions: Session[]
  goals: Goal[]
  citations: Citation[]
  indication: Indication
  thinkingTraps: ThinkingTrap[]
  primaryFeelings: PrimaryFeeling[]
  image: UuidDTO;
  // TODO rename to state when BE is ready
  publicationState: PublicationState;
}

export enum SessionState {
  COMPLETED = 'completed',
  UNLOCK = 'unlock',
  LOCK = 'lock',
  HOMEWORK_PENDING = 'homework_pending',
  NOT_JOINED = 'not_joined',
}

export interface Session {
  id: UuidDTO
  name: string
  description: string
  pages: Page[]
  homework: Homework[]
  goals: LayoutGoal[]
  state: SessionState
  isCompleted: boolean;
  completedDate: string
  unlockDate: string
  timeUntilUnlockMillis: number;
  image: UuidDTO;
}

export enum PageState {
  lOCK = 'lock',
  UNLOCK = 'unlock',
  COMPLETED = 'completed',
}

export interface Page {
  id: UuidDTO;
  name: string
  description: string
  components: Component[]
  state: PageState;
}

export enum ComponentType {
  JOURNAL = 'journal',
  LAYOUT = 'layout',
  TABLE = 'table',
  CONTENT = 'content',
  QUESTIONNAIRE = 'questionnaire',
  PROBLEM_SOLVING_WORKSHEET = 'problem_solving_worksheet',
  SMART_CONTENT = 'smart_content',
  CONCEPTUALIZATION_WORKSHEET = 'conceptualization_worksheet',
  QUESTIONNAIRE_REVIEW = 'questionnaire_review',
}

export enum LayoutComponentType {
  CONTENT = 'content',
  QUESTIONNAIRE = 'questionnaire',
  JOURNAL = 'journal',
  QUESTIONNAIRE_REVIEW = 'questionnaire_review',
  TABLE = 'table',
  GOAL = 'goal',
}

export interface QuestionnaireReview {
  id: UuidDTO;
  reviewAlgorithm: ReviewAlgorithm;
  content?: Content;
  reviewAlgorithmScore?: ReviewAlgorithmScore;
  completed: boolean;
}

export enum ReviewAlgorithm {
  STANDARD_REGRESSION = 'STANDARD_REGRESSION',
  SIMPLE_DIFFERENCE = 'SIMPLE_DIFFERENCE',
}

export enum ReviewAlgorithmScore {
  NEUTRAL = 'NEUTRAL',
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NO_EVIDENCE = 'NO_EVIDENCE',
}


export interface SecondaryFeeling {
  id: UuidDTO;
  name: string;
}

export interface PrimaryFeeling {
  id: UuidDTO;
  name: string;
  secondaryFeelings: SecondaryFeeling[];
}

export interface ConceptualizationWorksheet {
  userComponentId: UuidDTO;
  contentId: UuidDTO;
  situation: ConceptualizationWorksheetData<string>;
  primaryFeelingsIds: ConceptualizationWorksheetData<UuidDTO[]>;
  secondaryFeelingsIds: ConceptualizationWorksheetData<UuidDTO[]>;
  thoughts: ConceptualizationWorksheetData<string[]>;
  physicalReactions: ConceptualizationWorksheetData<string[]>;
  thinkingTraps: ConceptualizationWorksheetData<UuidDTO[]>;
  copingThoughts: ConceptualizationWorksheetData<string[]>;
  behaviors: ConceptualizationWorksheetData<string[]>;
  questionnaire: Questionnaire;
  isAnswered: boolean;
}

export interface ConceptualizationWorksheetData<T> {
  value: T;
  show: boolean;
  description?: string;
  example?: string;
}

export interface Component {
  type: ComponentType
  questionnaire: Questionnaire
  content: Content
  journal: Journal
  problemSolvingWorksheet: ProblemSolvingWorksheet
  conceptualizationWorksheet: ConceptualizationWorksheet
  questionnaireReview: QuestionnaireReview
  table: TableDTO
  componentLayout: Layout
}

export interface Layout {
  contentId: string;
  componentsLayout: ILayoutComponent[];
  displayType: LayoutDisplayType;
}

export enum LayoutDisplayType {
  ROW = 'row',
  COLUMN = 'column',
}

export interface ILayoutComponent {
  goal: LayoutGoal;
  type: LayoutComponentType;
  questionnaire: Questionnaire;
  content: Content;
  journal: Journal;
  questionnaireReview: QuestionnaireReview;
  table: TableDTO;
}

export interface TableCellDTO {
  id: UuidDTO;
  x: number;
  y: number;
  text: string;
}

export enum TableDisplayTypeDTO {
  HEADER = 'HEADER',
  NO_HEADER = 'NO_HEADER',
}

export interface TableDTO {
  id: UuidDTO;
  displayType: TableDisplayTypeDTO;
  cells: TableCellDTO[];
}

export enum QuestionnaireType {
  STANDARD = 'standard',
  SHOW_ONLY_QUESTIONS = 'show_only_questions',
  GRID_LAYOUT = 'grid_layout',
}

export interface Questionnaire {
  id: UuidDTO
  name: string
  description: string
  questions: Question[]
  citations: Citation[]
  score: Score | null;
  hasScore: boolean;
  isAnswered: boolean;
  type: QuestionnaireType
  image: UuidDTO | null;
}

export interface Question extends QuestionBase, QuestionMultiChoice, QuestionNumeric {
}

export interface QuestionNumeric extends QuestionBase {
  numeric: Numeric;
  numericSelected: number;
}

export interface QuestionBase {
  userComponentId: UuidDTO
  contentId: UuidDTO
  type: QuestionType
  name: string;
  description: string;
  image: UuidDTO | null;
  requiresHarmReductionCheck: boolean
  isAnswered: boolean
}

export enum QuestionType {
  MULTICHOICE = 'multichoice',
  NUMERIC = 'numeric',
}

export enum QuestionDisplayType {
  ORDERED_LIST = 'ORDERED_LIST',
  HORIZONTAL_SLIDER = 'HORIZONTAL_SLIDER',
  CHECKBOX = 'CHECKBOX',
  GRID = 'GRID',
  VERTICAL_SLIDER = 'VERTICAL_SLIDER',
  UNORDERED_LIST = 'UNORDERED_LIST',
}

export interface QuestionMultiChoice extends QuestionBase {
  multiChoice: MultiChoice
  displayType: QuestionDisplayType
  multiChoiceSelectedIds: UuidDTO[]
}

export interface MultiChoice {
  maxOptions: number
  minOptions: number
  options: Option[]
}

export interface Option {
  id: UuidDTO
  text: string
  value: number
  defaultAnswer: boolean;
  image?: UuidDTO;
}

export interface Numeric {
  maxValue: number
  minValue: number
  format: string
}

export interface Citation {
  id: UuidDTO
  name: string
  reference: string
}

export interface Score {
  score: number
  summary: string
  label: string
  maxValueInclusive: number;
  minValueInclusive: number;
}

export interface Content {
  id: UuidDTO;
  name: string | null; // no_text has no name
  contentHTML: string;
  audio: UuidDTO | null;
  image: UuidDTO | null;
  displayType: ContentDisplayType;
}

export enum ContentDisplayType {
  TEXT_LEFT = 'TEXT_LEFT',
  TEXT_RIGHT = 'TEXT_RIGHT',
  TEXT_TOP = 'TEXT_TOP',
  TEXT_BOTTOM = 'TEXT_BOTTOM',
  NO_TEXT = 'NO_TEXT',
}

export enum JournalType {
  STANDARD = 'standard',
  MULTICHOICE = 'multichoice',
}


export interface Journal {
  contentId: UuidDTO
  userComponentId: UuidDTO
  name: string
  prompt: string
  examples: string | null;
  answer: string;
  isAnswered: boolean;
  type: JournalType;
}

export interface ProblemSolvingWorksheet {
  problem: string
  goal: string;
  rewards: string[];
  table: TableDTO;
  solutions: Solution[]

  contentId: UuidDTO
  userComponentId: UuidDTO
  isAnswered: boolean;
  exampleGoal: string;
  exampleProblem: string;
  exampleSolution: string;
  exampleActivity: string;
  exampleRewards: string;
  descriptionGoal: string;
  descriptionProblem: string;
  descriptionSolution: string;
  descriptionActivity: string;
  descriptionRewards: string;
  solutionsInstructions: string;
  actionsInstructions: string;
}

export interface Solution {
  name: string
  goodThings: string
  badThings: string
  activities: string[]
}

export interface Homework {
  id: UuidDTO
  completedDate: string
  pages: Page[]
  userHomeworkId: undefined;
  contentId: UuidDTO
}


export interface Goal {
  id: UuidDTO
  name: string
  descriptionHTML: string
}

export enum LayoutGoalDisplayType {
  INCOMPLETE = 'incomplete',
  COMPLETE = 'complete',
}

export interface LayoutGoal extends Goal {
  displayType: LayoutGoalDisplayType
}

export interface Indication {
  id: UuidDTO
  name: string
  description: string
  citations: Citation[]
}
