<script setup lang="ts">
import {
  QuestionDisplayType,
  type QuestionMultiChoice,
} from '@/ts/types/dto/interventions-dto';
import {
  computed,
  onBeforeMount,
  watch,
} from 'vue';
import {
  type QuestionAnswerMultiChoiceBody,
  QuestionnaireType,
} from '@/ts/types/dto/session-request-dto';
import ErrorBox from '@/vue/molecules/error-box.vue';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import QuestionSlider from '@/vue/molecules/components/question/question-slider.vue';
import QuestionCheckbox from '@/vue/molecules/components/question/question-checkbox.vue';
import QuestionGridSelect from '@/vue/molecules/components/question/question-grid-select.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';

const props = defineProps<{
  question: QuestionMultiChoice;
  displayListWithQuestionInRow?: boolean;
}>();

defineEmits<{
  readyToGoNext: [],
}>();

const model = defineModel<QuestionAnswerMultiChoiceBody | null>();
const logger = useLogger();

const value = computed<UuidDTO[]>({
  get() {
    return model.value?.multiChoice ?? [];
  },
  set(newValue) {
    model.value = {
      type: QuestionnaireType.MULTICHOICE,
      multiChoice: newValue,
    };
  },
});

onBeforeMount(() => {
  logger.debug(`onBeforeMount ${props.question.contentId}`)();
  if (model.value && value.value.length > 0) {
    logger.trace(`Value of ${props.question.contentId} already set`)();
  } else if (props.question.multiChoiceSelectedIds?.length > 0) {
    value.value = props.question.multiChoiceSelectedIds;
  } else if (!model.value) {
    value.value = []; // emit to the top not-null value
  }
});

watch(() => props.question.contentId, () => {
  logger.debug(`watch ${props.question.contentId}`)();
  // emit value to the parents

  if (!model.value?.multiChoice) {
    value.value = props.question.multiChoiceSelectedIds;
  }
});


</script>
<template>
  <question-grid-select
    v-if="question.displayType === QuestionDisplayType.ORDERED_LIST"
    v-model="value"
    :theme="displayListWithQuestionInRow ? 'list-question-in-row' : 'list-question-in-col'"
    :question="question"
    @ready-to-go-next="$emit('readyToGoNext')"
  />
  <question-slider
    v-else-if="question.displayType === QuestionDisplayType.HORIZONTAL_SLIDER"
    v-model="value"
    :question="question"
  />
  <question-checkbox
    v-else-if="question.displayType === QuestionDisplayType.CHECKBOX"
    v-model="value"
    :question="question"
  />
  <question-grid-select
    v-else-if="question.displayType === QuestionDisplayType.GRID"
    v-model="value"
    theme="chips"
    :question="question"
  />
  <error-box v-else/>
</template>

