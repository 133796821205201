<script setup lang="ts">
import {
  onBeforeMount,
  ref,
} from 'vue';

import {pagesPath} from '@/ts/router/pages-path';
import {useLoadingCb} from '@/ts/composables/pure/use-loading-data-ref';
import {useApi} from '@/ts/composables/stateful/use-api';
import LoadingError from '@/vue/templates/loading-error.vue';
import {useUserStore} from '@/ts/store/user-store';

import GlobalPageTemplate from '@/vue/templates/global-page-template.vue';
import type {NavigationItem} from '@/ts/types/component/navigation';
import {useUserSidebarMenu} from '@/ts/composables/stateful/use-balm-sidebar-menu';
import {useReccoOnboardingState} from '@/ts/composables/pure/use-recco-onboarding-state';

const api = useApi();
const userStore = useUserStore();
const {syncReccoOnboardingState} = useReccoOnboardingState();
const {balmSidebarMenuItems} = useUserSidebarMenu();

const mainNavigationItems = ref<NavigationItem[]>([
  {
    path: pagesPath.intervention.interventionsList,
    labelKey: 'paths',
    id: 'paths',
  },
  {
    path: pagesPath.main.explore,
    labelKey: 'recco_explore',
    id: 'recco_explore',
  },
]);

const [fetchMe, meLoading, meErr] = useLoadingCb(
  async() => Promise.all([
      api.me.getMe(),
      api.me.getHomeContent(),
      syncReccoOnboardingState(),
  ]),
  res => {
    userStore.setUserData(res[0]);
    userStore.setHomeContent(res[1]);
  },
);

onBeforeMount(async() => {
  await fetchMe();
});
</script>
<template>
  <loading-error id="main-base-page" :loading="meLoading" :error="meErr" type="page-ignore-header">
    <global-page-template
      :enable-guide-drawer="true"
      :main-navigation-items="mainNavigationItems"
      :sidebar-menu-items="balmSidebarMenuItems"
    >
      <router-view/>
    </global-page-template>
  </loading-error>
</template>
<style scoped lang="scss">

</style>
