<script lang="ts" setup>
/**
 * This is a pure UI component.
 * The component provides no own click-handler or tab-index.
 * Use it within the aria-list-box component.
 */

defineProps<{
  label: string;
  isSelected?: boolean;
  isActive?: boolean;
}>();

</script>

<template>
  <div
    v-ripple.center
    class="answer-option"
    :class="{
      'answer-option--selected': isSelected,
      'answer-option--active': isActive,
    }"
  >
    <span>{{ label }}</span>
  </div>
</template>

<style lang="scss" scoped>

.answer-option {
  @include font-body-bold;
  width: 100%;
  background-color: var(--btn-background-on-card);
  border-radius: var(--Spacing-spacing-1);
  padding: var(--Spacing-spacing-3);
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &--selected {
    color: var(--Neutral-UI-neutral-ui-100);
    background-color: var(--Secondary-secondary-color-1);
  }

  &:not(#{&}--selected):hover {
    background-color: var(--Neutral-UI-neutral-ui-20);
  }
}

// keyboard control only
:focus-visible .answer-option--active {
  @include focus-visible-default-styling;

  &:not(.answer-option--selected) {
    background-color: var(--Neutral-UI-neutral-ui-20);
  }
}

</style>