<script setup lang="ts">

import {computed} from 'vue';
import {
  type InterventionPreview,
  PublicationState,
} from '@/ts/types/dto/interventions-dto';
import {pagesPath} from '@/ts/router/pages-path';
import CompletedProgress from '@/vue/atoms/completed-progress.vue';
import {getVueLink} from '@/ts/composables/stateful/use-route-params';
import LazyImage from '@/vue/atoms/lazy-image.vue';
import InterventionLabel from '@/vue/molecules/intervention-label.vue';
import InterventionFooter from '@/vue/molecules/intervention-footer.vue';

const props = defineProps<{ intervention: InterventionPreview }>();
const intervention = computed<InterventionPreview>(() => props.intervention);
const interventionState = computed(() => {
  const stateMap: Record<PublicationState, string> = {
    [PublicationState.IN_PROGRESS]: 'in-progress',
    [PublicationState.WITHDREW]: 'withdrew',
    [PublicationState.COMPLETED]: 'completed',
    [PublicationState.NOT_JOINED]: 'not-joined',
    [PublicationState.ACTIVE]: 'active',
    [PublicationState.NEW]: 'new',
    [PublicationState.MAINTENANCE]: 'maintenance',
    [PublicationState.DISCONTINUED]: 'discontinued',
    [PublicationState.PREVIEW]: 'preview',
    [PublicationState.PUBLISHED]: 'published',
  };
  return stateMap[intervention.value.publicationState] ?? 'not-started';
});

const recapLink = computed(() => getVueLink(pagesPath.intervention.interventionRecap, {iid: intervention.value.id}));
</script>

<template>
  <div class="intervention" :class="interventionState">
    <div class="top-row">
      <lazy-image :src="intervention.image!" :width="367" class="img"/>
      <intervention-label class="label" :publication-state="intervention.publicationState"/>
    </div>
    <div class="section">
      <h5 class="title">
        {{ intervention.name }}
      </h5>
      <span class="description">{{ intervention.description }}</span>
      <router-link v-if="intervention.publicationState === PublicationState.COMPLETED" class="recap-link" :to="recapLink">
        <span>{{ $t('intervention_recap') }}</span>
      </router-link>
      <div class="q-mt-auto"/>
      <completed-progress
        v-if="PublicationState.IN_PROGRESS === intervention.publicationState"
        :completed="intervention.completionProgress ?? 0"
        class="progress"
      />
      <intervention-footer :intervention="intervention"/>
    </div>
  </div>
</template>
<style scoped lang="scss">
.progress {
  margin: var(--Spacing-spacing-3) 0;
}

.intervention {
  @include q-card-20;
  width: 100%;
  flex-direction: column;
  min-height: 500px;
  max-height: 800px;
  display: flex;
  border-radius: var(--card-border-radius);

  @include screen-md {
    width: calc(50% - var(--Spacing-spacing-3));
  }

  @include screen-lg {
    width: 367px;
    min-height: 600px;
  }

  &.in-progress {
    @include q-card-blue;
  }

  &.completed {
    @include q-card-green;
  }

  &.maintenance {
    @include q-card-red;
  }

  &.preview {
    @include q-card-30;
  }
}

.description {
  @include font-body-sm;
  margin: var(--Spacing-spacing-3) 0;

  @include screen-lg {
    @include font-body-sm;
  }
}

.section {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--Spacing-spacing-4);
  font-size: 16px;

  @include screen-lg {
    padding: var(--Spacing-spacing-6);
  }
}

.label {
  position: absolute;
  right: var(--Spacing-spacing-4);
  top: var(--Spacing-spacing-4);

  @include screen-md {
    right: var(--Spacing-spacing-6);
    top: var(--Spacing-spacing-5);
  }
}

.top-row {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.img {
  border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
}

.recap-link {
  text-decoration: underline;
}
</style>
