<script setup lang="ts">
import type {ContentGlossary} from '@/ts/types/dto/home-dto';
import {computed} from 'vue';
import type {SideBarGroupedSection} from '@/ts/types/component/balm-sidebar';
import SidebarName from '@/vue/molecules/sidebar/sidebar-name.vue';
import {groupByCategory} from '@/ts/composables/pure/use-content-sidebar';

const props = defineProps<{
  glossaries: ContentGlossary[];
}>();

const grouped = computed<SideBarGroupedSection[]>(() => groupByCategory(props.glossaries, item => ({
  id: item.id,
  html: item.definition,
  label: item.term,
})));

</script>
<template>
  <sidebar-name :name="$t('glossary')"/>
  <div class="glossary">
    <template v-for="group in grouped" :key="group.id">
      <div class="glossary-category">
        {{ group.name }}
      </div>
      <div v-for="term in group.items" :key="term.id" class="glossary-item">
        <div class="glossary-term">
          {{ term.label }}
        </div>
        <div class="glossary-definition" v-html="term.html"/>
      </div>
    </template>
  </div>
</template>
<style scoped lang="scss">
.glossary {
  margin: var(--Spacing-spacing-6) 0;
}

.glossary-category {
  @include font-body-bold;
}

.glossary-item {
  padding: var(--Spacing-spacing-4) 0;
  border-bottom: 1px solid var(--Neutral-UI-neutral-ui-30, #CCCAC1);

  @include screen-md {
    display: grid;
    grid-template-columns: 200px auto
  }
}

.glossary-term {
  padding-bottom: var(--Spacing-spacing-1);
  @include font-body-bold;

  @include screen-md {
    padding-bottom: 0;
    grid-column: 1;
  }
}

.glossary-definition {
  @include font-body-sm;

  @include screen-md {
    grid-column: 2;
  }
}
</style>
