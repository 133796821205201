<script lang="ts" setup>
import {type Page, PageState} from '@/ts/types/dto/interventions-dto';
import {computed, ref} from 'vue';
import {getVueLink, useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';
import {pagesPath} from '@/ts/router/pages-path';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import {RouterLink} from 'vue-router';

// These items may have a disabled link in some cases.
// Generally links do not provide a disable mode
// This solution follows the accessibility guide by Orange: https://a11y-guidelines.orange.com/en/articles/disable-elements/#disable-a-link

const props = defineProps<{
  page: Page;
  index: number;
  isInactive?: boolean;
}>();

const {sid, iid, pid} = useRouterSectionParams();

const pageState = computed<'current'|'not-started'|'in-progress'|'completed'>(() => {
  // return values are used by css-class!
  if (props.index === pid.value) {
    return 'current';
  }

  return  {
    [PageState.COMPLETED]: 'completed',
    [PageState.lOCK]: 'not-started',
    [PageState.UNLOCK]: 'in-progress',
  }[props.page.state] as 'current'|'not-started'|'in-progress'|'completed';
});

const isLinkDisabled = computed<boolean>((): boolean => {
  return props.page.state === PageState.lOCK;
});

const href = computed(() => {
  return isLinkDisabled.value ? null : getVueLink(pagesPath.session.sessionPage, {
    iid: iid.value,
    sid: sid.value,
    pid: props.index,
  });
});

const linkEl = ref();

function focus(): void {
  (linkEl.value?.$el || linkEl.value)?.focus(); //eslint-disable-line
};

defineExpose({focus});

</script>
<template>
  <li class="session-toc-item">
    <component
      :is="isLinkDisabled ? 'a' : RouterLink"
      ref="linkEl"
      v-ripple.center
      :to="href"
      class="link focus-visible"
      :class="pageState"
      :aria-disabled="isLinkDisabled ? isLinkDisabled : null"
      role="link"
      :tabindex="isInactive ? '-1' : null"
    >
      <div class="label">
        <span class="text-body-sm">{{ $t('page_n', {n: index}) }}</span>
        <span v-if="page.name" class="text-body-sm-bold">
          {{ page.name }}
        </span>
      </div>
      <div class="icon">
        <svg-illustration v-if="pageState === 'current'" name="arrow-narrow-right.svg"/>
        <svg-illustration v-else-if="pageState === 'completed'" name="check.svg"/>
      </div>
    </component>
  </li>
</template>

<style lang="scss" scoped>

.session-toc-item {
  list-style: none;
  position: relative;
}

.link {
  width: 100%;
  display: flex;
  padding: var(--Spacing-spacing-3);
  position: relative;
}

.not-started {
  @include q-card;
  cursor: not-allowed;
}

.current {
  @include q-card-blue;
  flex-direction: row;
}

.in-progress {
  @include q-card-white;
  flex-direction: row;
}

.completed {
  @include q-card-green;
  flex-direction: row;
}

.label {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-1);
  align-items: flex-start;
  text-align: left;
}

.icon {
  align-self: end;
  margin-left: auto;
  position: relative;
  top: 1px;
}

</style>
