<script setup lang="ts">
import ExampleBlock from '@/vue/molecules/components/helpers/example-block.vue';
import {useRequiredRule} from '@/ts/composables/pure/use-input-rules';
import type {ComponentTheme} from '@/ts/types/component/layout-component-types';

defineProps<{
  name: string;
  exampleHtml?: string | null;
  descriptionHtml?: string | null;
  theme: ComponentTheme;
}>();

const model = defineModel<string>();
const requiredRule = useRequiredRule();

</script>
<template>
  <div class="example-single-input" :class="theme">
    <div class="instructions">
      <h5 class="title">
        {{ name }}
      </h5>
      <div v-if="descriptionHtml" class="description" v-html="descriptionHtml"/>
    </div>
    <example-block v-if="exampleHtml" class="example" :example-html="exampleHtml"/>
    <q-input
      v-model="model"
      class="input"
      :rules="[requiredRule]"
      type="textarea"
      borderless
    />
  </div>
</template>
<style scoped lang="scss">
.example-single-input.card {
  @include q-card-white;
  padding: var(--Spacing-spacing-4);

  @include screen-lg {
    padding: var(--Spacing-spacing-7);
  }
}

.example-single-input {
  display: grid;
  gap: var(--Spacing-spacing-4);

  @include screen-lg {
    grid-template-columns: 1fr 1fr;
  }
}

.title {
  color: var(--Neutral-UI-neutral-ui-100);
  margin-bottom: var(--Spacing-spacing-4);
}

.instructions {
  @include screen-lg {
    grid-column: 1;
    grid-row: 1;
  }
}

.example {
  @include screen-lg {
    grid-column: 2;
    grid-row: span 2;
    height: fit-content;
  }
}

.input {
  @include screen-lg {
    grid-column: span 2;
    grid-row: 3;
  }

  &:deep(textarea) {
    padding: var(--Spacing-spacing-4);
    background-color: var(--Neutral-UI-neutral-ui-10);
    color: var(--Neutral-UI-neutral-ui-100);
  }
}
</style>
