import type {FetchWrapper} from '@/ts/api/fetch-wrapper';
import type {
  ReccoCreateQuestionnaireAnswerDTO,
  ReccoQuestionnaireResponse,
ReccoNextAvailableQuestionnaireResponse} from '@/ts/types/dto/recco/questionnaire.dto';
import {getNulledUuid} from '@/ts/utils/pure-functions';
import {ReccoQuestionnaireCategory} from '@/ts/types/component/recco-questionnaire.type';
import {type ReccoRecommendationResponse, ReccoRecommendationTypeDTO} from '@/ts/types/dto/recco/recommendation.dto';
import type {ReccoTopicDTO} from '@/ts/types/dto/recco/topic.dto';
import type {ReccoArticleDTO} from '@/ts/types/dto/recco/article.dto';
import type {ReccoAudioDTO} from '@/ts/types/dto/recco/audio.dto';
import type {ReccoVideoDTO} from '@/ts/types/dto/recco/video.dto';

export class ReccoApi {
  public constructor(private readonly fetch: FetchWrapper) {
  }

  public async getQuestionnaire(category: ReccoQuestionnaireCategory, questionnaireId?: string): Promise<ReccoQuestionnaireResponse> {
    let responsePayload: ReccoQuestionnaireResponse;

    if (questionnaireId) {
      responsePayload = await this.getQuestionnaireById(questionnaireId!);
    } else if (category === ReccoQuestionnaireCategory.ONBOARDING) {
      responsePayload = await this.getOnboardingQuestionnaire();
    } else {
      responsePayload = await this.getQuestionnaireByTopic(category.toString() as ReccoTopicDTO);
    }

    return responsePayload;
  }

  public async getOnboardingQuestionnaire(): Promise<ReccoQuestionnaireResponse> {
    return this.fetch.get({
      url: '/questionnaire/onboarding',
    });
  }

  public async getQuestionnaireByTopic(topic: ReccoTopicDTO): Promise<ReccoQuestionnaireResponse> {
    return this.fetch.get({
      url: `/questionnaire/${topic}`,
    });
  }

  public async getQuestionnaireById(id: string): Promise<ReccoQuestionnaireResponse> {
    return this.fetch.get({
      url: '/questionnaire',
      queryParams: {
        itemId: id,
      },
    });
  }

  public async getNextAvailableQuestionnaire(): Promise<ReccoNextAvailableQuestionnaireResponse|null> {
    return this.fetch.get({
      url: '/questionnaire/next',
      handle404: true,
    });
  }

  public async postQuestionnaireAnswers(body: ReccoCreateQuestionnaireAnswerDTO[], isOnboarding: boolean = false): Promise<void> {
    // API expects the properties "questionId" and "questionnaireId", but this is something that not exists in context of Recco
    const somethingThatLooksLikeAnUuidToFoolTheEndpointValidation = getNulledUuid();
    const validBody = body.map((bodyItem): unknown => {
      return {
        questionId: somethingThatLooksLikeAnUuidToFoolTheEndpointValidation,
        questionnaireId: somethingThatLooksLikeAnUuidToFoolTheEndpointValidation,
        ...bodyItem,
      };
    });

    if (isOnboarding) {
      return this.fetch.post({
        url: '/questionnaire/onboarding',
        body: validBody,
      });
    }

    return this.fetch.post({
      url: '/questionnaire/answers',
      body: validBody,
    });
  }

  public async getPreferred(): Promise<ReccoRecommendationResponse> {
    return this.fetch.get({
      url: '/recommendation/preferred',
      queryParams: {
        // default contentTypes value BE-side is "article"
        contentTypes: [ReccoRecommendationTypeDTO.Article, ReccoRecommendationTypeDTO.Audio, ReccoRecommendationTypeDTO.Video],
      },
    });
  }

  public async getStarting(): Promise<ReccoRecommendationResponse> {
    return this.fetch.get({
      url: '/recommendation/starting',
      queryParams: {
        // default contentTypes value BE-side is "article"
        contentTypes: [ReccoRecommendationTypeDTO.Article, ReccoRecommendationTypeDTO.Audio, ReccoRecommendationTypeDTO.Video],
      },
    });
  }

  public async getExploreByTopic(topic: ReccoTopicDTO): Promise<ReccoRecommendationResponse> {
    return this.fetch.get({
      url: `/explore/topics/${topic}`,
      queryParams: {
        // default contentTypes value BE-side is "article"
        contentTypes: [ReccoRecommendationTypeDTO.Article, ReccoRecommendationTypeDTO.Audio, ReccoRecommendationTypeDTO.Video],
      },
    });
  }

  public async getTailoredByTopic(topic: ReccoTopicDTO): Promise<ReccoRecommendationResponse> {
    return this.fetch.get({
      url: `/tailored/topics/${topic}`,
      queryParams: {
        // default contentTypes value BE-side is "article"
        contentTypes: [
          ReccoRecommendationTypeDTO.Article,
          ReccoRecommendationTypeDTO.Audio,
          ReccoRecommendationTypeDTO.Video,
        ],
      },
    });
  }

  public async getArticle(catalogId: string, itemId: string): Promise<ReccoArticleDTO> {
    return this.fetch.get({
      url: '/recommendation/article',
      queryParams: {
        itemId,
        catalogId,
      },
    });
  }

  public async getAudio(catalogId: string, itemId: string): Promise<ReccoAudioDTO> {
    return this.fetch.get({
      url: '/recommendation/audio',
      queryParams: {
        itemId,
        catalogId,
      },
    });
  }

  public async getVideo(catalogId: string, itemId: string): Promise<ReccoVideoDTO> {
    return this.fetch.get({
      url: '/recommendation/video',
      queryParams: {
        itemId,
        catalogId,
      },
    });
  }
}