<script setup lang="ts">
import {useI18n} from 'vue-i18n';
import ExampleBlock from '@/vue/molecules/components/helpers/example-block.vue';
import {useRequiredRule} from '@/ts/composables/pure/use-input-rules';
import FlatIconBtn from '@/vue/molecules/flat-icon-btn.vue';
import type {QuasarOption} from '@/ts/types/component/select-multichoice';
import {computed} from 'vue';
import BalmBtn from '@/vue/atoms/balm-btn.vue';

const props = defineProps<{
  exampleHtml?: string | null;
  solution: string;
  descriptionHtml?: string | null;
  options: QuasarOption<number>[];
}>();
const {t: $t} = useI18n();

const model = defineModel<string[]>();
const index = defineModel<number>('index');
const requiredRule = useRequiredRule();

function deleteItem(int: number): void {
  model.value!.splice(int, 1);
}

const allOptions = computed(() => {
  return [...props.options, {label: props.solution, value: index.value}];
});

const currentIndex = computed<QuasarOption<number>>({
  get() {
    return {label: props.solution, value: index.value!};
  },
  set(val) {
    index.value = val.value;
  },
});

</script>
<template>
  <div class="possible-activities">
    <div class="instructions">
      <h5 class="title">
        {{ $t('possible_activities') }}
      </h5>
      <div v-if="descriptionHtml" class="description" v-html="descriptionHtml"/>
    </div>
    <example-block v-if="exampleHtml" class="example" :name="$t('solution')" :example-html="exampleHtml"/>
    <div class="inputs">
      <div v-if="allOptions.length > 1" class="chose-solution">
        <h5>{{ $t('chose_solution') }}</h5>
        <q-select v-model="currentIndex" :options="allOptions"/>
      </div>
      <div v-else class="chose-solution">
        <h5>{{ $t('solution') }}</h5>
        <h5>{{ allOptions[0].label }}</h5>
      </div>
      <div v-for="(item, i) in model" :key="i" class="each-row">
        <flat-icon-btn
          v-if="(i + 1) === model!.length && i >= 1"
          class="trash"
          icon="trash.svg"
          @click="deleteItem(i)"
        />
        <q-input
          v-model="model![i]"
          bg-color="white"
          :rules="[requiredRule]"
          type="textarea"
        />
      </div>
    </div>
    <balm-btn
      theme="grey"
      :label="$t('add_item')"
      class="btn"
      icon="plus.svg"
      @click="model!.push('')"
    />
  </div>
</template>
<style scoped lang="scss">
.possible-activities {
  @include q-card-white;
  padding: var(--Spacing-spacing-4);
  display: grid;
  gap: var(--Spacing-spacing-4);

  @include screen-lg {
    padding: var(--Spacing-spacing-7);
    grid-template-columns: 1fr 2fr;
  }
}

.instructions {
  @include screen-lg {
    grid-column: 1;
    grid-row: 1;
  }
}

.title {
  color: var(--Neutral-UI-neutral-ui-100);
  margin-bottom: var(--Spacing-spacing-4);
}

.description {
  @include screen-lg {
    grid-column: 1;
    grid-row: 2;
  }
}

.example {
  @include screen-lg {
    grid-column: 2;
    grid-row: span 2;
    height: fit-content;
  }
}

.trash {
  position: absolute;
  width: 20px;
  z-index: 2;
  top: var(--Spacing-spacing-2);
  right: var(--Spacing-spacing-2);
  cursor: pointer;
}

.each-row {
  position: relative;
}

.chose-solution {
  background-color: var(--color-white);
  padding: var(--Spacing-spacing-6);
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-4);
}

.inputs {
  gap: var(--Spacing-spacing-2);
  display: flex;
  flex-direction: column;

  @include screen-lg {
    grid-column: span 2;
    grid-row: 3;
  }

  :deep(textarea) {
    padding: var(--Spacing-spacing-6);
  }
}

.btn {
  margin-left: auto;
  
  @include screen-lg {
    grid-column: 2;
  }
}
</style>
