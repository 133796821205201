import type {RouteRecordRaw} from 'vue-router';
import {pagesPath} from '@/ts/router/pages-path';
import InterventionFetchPage from '@/vue/pages/interventions/intervention-fetch-page.vue';
import InterventionSessionPage from '@/vue/pages/interventions/intervention-session-page.vue';

export const sessionPages: RouteRecordRaw[] = [
  {
    path: pagesPath.session.sessionWrapper,
    component: InterventionFetchPage,
    children: [
      {
        path: pagesPath.session.sessionPage,
        component: InterventionSessionPage,
      },
    ],
  },
];



