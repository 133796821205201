/*
 eslint-disable @typescript-eslint/naming-convention,
 @typescript-eslint/ban-types,
 import/no-duplicates,
 @typescript-eslint/no-explicit-any,
 import/no-default-export,
 import/unambiguous
 */

import type {
  LogLevel,
} from 'lines-logger';

declare const APP_CONSTS: {
  IS_DEBUG: boolean;
  BACKEND_ADDRESS: string;
  PUBLIC_PATH: string | null;
  GIT_HASH: string;
  ROUTER_BASE_URL?: string;
  MEDIA_URL: string;
  RECCO_MEDIA_URL: string;
  LANGUAGE: 'de' | 'en';
  MULTI_LANGUAGE: boolean;
  SHOW_WITHDRAW_BTN: boolean;
  SHOW_UNLOCK_BTN: boolean;
  LOGOUT_ON_401: boolean;
  DISABLE_LOADING?: boolean;
  RECAPTHCA_SITE_KEY?: string;
  SHOW_REVISION?: boolean;
  SHOW_SCREEN_SIZE?: boolean;
  LOG_LEVEL: LogLevel;
  'HOTJAR': {
    'id': number,
    'sv': number
  },
  SIGN_UP_FORM_DEFAULT_VALUES?: {
    email: string;
    name: string;
    country: string;
    marketingCode: string;
    birthYear: number;
    education: any;
    gender: {
      label: string;
      value: any;
    };
  }
  ZITADEL: ZitadelConfig;
};

export const {
  IS_DEBUG,
  BACKEND_ADDRESS,
  DISABLE_LOADING,
  PUBLIC_PATH,
  GIT_HASH,
  ROUTER_BASE_URL,
  ZITADEL,
  SIGN_UP_FORM_DEFAULT_VALUES,
  SHOW_WITHDRAW_BTN,
  SHOW_UNLOCK_BTN,
  MEDIA_URL,
  RECCO_MEDIA_URL,
  LANGUAGE,
  LOG_LEVEL,
  MULTI_LANGUAGE,
  LOGOUT_ON_401,
  SHOW_REVISION,
  SHOW_SCREEN_SIZE,
  HOTJAR,
  RECAPTHCA_SITE_KEY,
} = APP_CONSTS;


export interface ZitadelConfig {
  organization_resource_id: string;
  project_resource_id: string;
  issuer: string;
  client_id: string;
}
