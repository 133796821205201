<script setup lang="ts">
import type {Intervention} from '@/ts/types/dto/interventions-dto';
import {useI18n} from 'vue-i18n';
import RecapComponent from '@/vue/molecules/components/recap-component.vue';

const {t: $t} = useI18n();

defineProps<{ intervention: Intervention }>();

</script>

<template>
  <div class="intervention-landing-recap">
    <h3 class="title">
      {{ $t('recap') }}
    </h3>
    <recap-component :intervention="intervention"/>
  </div>
</template>

<style scoped lang="scss">
.intervention-landing-recap {
  border-radius: var(--Border-Radius-1);
  background-color: var(--Neutral-UI-neutral-ui-0);
  padding: 32px 24px 42px 24px;

  @include screen-md {
    padding: 42px 32px 48px 32px;
  }

  @include screen-lg {
    padding: 48px 40px 64px 40px;
  }
}

.title {
  @include font-h3-bold;
  margin-bottom: 16px;

  @include screen-md {
    margin-bottom: 24px;
  }

  @include screen-lg {
    margin-bottom: 32px;
  }
}
</style>
