<script setup lang="ts">
import {onMounted, ref} from 'vue';
import {useApi} from '@/ts/composables/stateful/use-api';
import SignUpForm from '@/vue/organisms/sign-up/sign-up-form.vue';
import SignUpWelcome from '@/vue/organisms/sign-up/sign-up-welcome.vue';
import BalmStepper from '@/vue/atoms/stepper/balm-stepper.vue';
import BalmStep from '@/vue/atoms/stepper/balm-step.vue';
import SignUpVerificationSent from '@/vue/organisms/sign-up/sign-up-verification-sent.vue';
import {useLoadingDataRefNull} from '@/ts/composables/pure/use-loading-data-ref';
import SignUpHeader from '@/vue/molecules/sign-up-header.vue';
import SignUpConsent from '@/vue/organisms/sign-up/sign-up-consent.vue';
import SignUpLegalDocsModal from '@/vue/molecules/sign-up-legal-docs-modal.vue';
import LoadingError from '@/vue/templates/loading-error.vue';
import GlobalPageTemplate from '@/vue/templates/global-page-template.vue';
import {LegalModel} from '@/ts/types/component/public-sign-up-page';
import type {NavigationItem} from '@/ts/types/component/navigation';
import {useDrawerStore} from '@/ts/store/drawer-store';
import {useRouter} from 'vue-router';
import {useLang} from '@/ts/composables/stateful/use-lang';
import {Language} from '@/ts/types/component/localization.type';

const WELCOME_PAGE = 1;
const CONSENT_PAGE = 2;
const USER_DATA_PAGE = 3;
const EMAIL_SENT_PAGE = 4;

const api = useApi();
const step = ref(WELCOME_PAGE);
const email = ref<string>();
const [legalDocs, loading, error] = useLoadingDataRefNull(async() => api.public.getLegalDocs());

function onSubmit(setEmail: string): void {
  email.value = setEmail;
  step.value++;
}
const legalModel = ref<LegalModel|null>(null);
const signUpFormRef = ref<typeof SignUpForm>(null!);
const signUpAgreeRef = ref(null);
const marketingCodeParam = ref<string>('');

const {setLanguage} = useLang();

const mainMenuItem = ref<NavigationItem[]>([
  {
    id: LegalModel.IMPRINT,
    labelKey: 'imprint',
  },
  {
    id: LegalModel.TERMS_OF_SERVICE,
    labelKey: 'terms_of_use',
  },
  {
    id: LegalModel.PRIVACY_POLICY,
    labelKey: 'privacy_policy',
  },
]);

function goNext(): void {
  if (step.value === WELCOME_PAGE) {
    step.value++;
  } else if (step.value === CONSENT_PAGE) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    (signUpAgreeRef.value! as any).submitForm();
  } else if (step.value === USER_DATA_PAGE) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    (signUpFormRef.value! as any).submitForm();
  }
}

const drawerStore = useDrawerStore();

function onClickMainNavigationItem(itemId: string): void {
  legalModel.value = itemId as LegalModel;
  drawerStore.closeHamburgerMenuDrawer();
};

onMounted(() => {
  const router = useRouter();
  const queryParams = router.currentRoute.value.query;
  if (queryParams?.code) {
    step.value = CONSENT_PAGE;
    marketingCodeParam.value = queryParams.code as string;
  }
  if (queryParams?.language === Language.German) {
    setLanguage(Language.German);
  }
  if (queryParams?.language === Language.English) {
    setLanguage(Language.English);
  }
});

</script>
<template>
  <loading-error id="public-sign-up-page" :loading="loading" type="page" :error="error">
    <global-page-template
      :main-navigation-items="mainMenuItem"
      :sidebar-menu-items="[]"
      @click-main-navigation-item="onClickMainNavigationItem($event)"
    >
      <div class="public-sign-up-page">
        <sign-up-legal-docs-modal v-model:legal-model="legalModel" :legal-docs="legalDocs!"/>
        <div class="public-sign-up-page-body">
          <sign-up-header/>
          <!-- The purpose of stepper instead of vue-router page is to have the same page -->
          <!-- that resets to the first one on page refresh -->
          <!-- thus user has to go through privacy policy each time -->
          <balm-stepper
            v-model="step"
            :show-back-button="step > WELCOME_PAGE && step < EMAIL_SENT_PAGE"
            :show-next-button="step < EMAIL_SENT_PAGE"
            @next="goNext"
          >
            <balm-step :index="WELCOME_PAGE" icon="sign-up/asterisk-02.svg" :title="$t('welcome')">
              <sign-up-welcome
                :name="legalDocs!.welcome.name"
                :html="legalDocs!.welcome.legalCopyHTML"
              />
            </balm-step>
            <balm-step :index="CONSENT_PAGE" icon="sign-up/data.svg" :title="$t('consent')">
              <sign-up-consent
                ref="signUpAgreeRef"
                :privacy="legalDocs!.privacyPolicy"
                :terms="legalDocs!.termsAndConditions"
                @finish="step++"
              />
            </balm-step>
            <balm-step :index="USER_DATA_PAGE" icon="sign-up/user-square.svg" :title="$t('information')">
              <sign-up-form
                ref="signUpFormRef"
                :terms-and-conditions-id="legalDocs!.termsAndConditions.id"
                :privacy-policy-id="legalDocs!.privacyPolicy.id"
                :marketing-code-param="marketingCodeParam"
                @finish="onSubmit"
              />
            </balm-step>
            <balm-step :index="EMAIL_SENT_PAGE" icon="sign-up/check-circle.svg" :title="$t('verification')">
              <sign-up-verification-sent :email="email"/>
            </balm-step>
          </balm-stepper>
        </div>
      </div>
    </global-page-template>
  </loading-error>
</template>
<style lang="scss" scoped>
.public-sign-up-page {
  background-color: var(--Neutral-UI-neutral-ui-0);
  border-radius: var(--border-radius);
  min-height: 100%;
  padding: var(--Spacing-spacing-4);

  @include screen-lg {
    background-color: transparent;
    min-height: auto;
    padding: 0;
    margin: var(--Spacing-spacing-4) 0;
  }
}

.public-sign-up-page-body {
  max-width: 900px;
  width: 100%;
  margin: auto;
  @include screen-lg {
    padding: 0 var(--Spacing-spacing-7);
  }
}
</style>
