<script setup lang="ts">

import type {Goal} from '@/ts/types/dto/interventions-dto';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';

defineProps<{
  goal: Goal;
  order: number;
  finished?: boolean;
}>();
</script>
<template>
  <div class="goal" :class="{finished}">
    <div class="goal-heading">
      <span class="order">{{ order }}</span>
      <svg-illustration v-if="finished" name="check.svg"/>
    </div>
    <div class="headgoal">
      {{ goal.name }}
    </div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-if="!finished" class="body" v-html="goal.descriptionHTML"/>
    <div class="q-mt-auto"/>
  </div>
</template>
<style scoped lang="scss">
.goal-heading {
  @include font-metric-lg;
  color: var(--Neutral-UI-neutral-ui-100);
  margin-bottom: var(--Spacing-spacing-7);
  display: flex;
  justify-content: space-between;
}

.headgoal {
  @include font-body-bold;
  margin-bottom: var(--Spacing-spacing-2);
}

.body {
  @include font-body-sm
}

.goal {
  width: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  @include q-card-10;
  @include q-card-big;
  gap: 0;

  @container (min-width: 664px) {
    max-width: 320px;
  }

  &.finished {
    @include q-card-green;
  }
}
</style>
