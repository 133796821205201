<script setup lang="ts">
import type {QuestionNumeric} from '@/ts/types/dto/interventions-dto';
import {
  computed,
  onBeforeMount,
} from 'vue';
import {
  type QuestionAnswerNumericBody,
  QuestionnaireType,
} from '@/ts/types/dto/session-request-dto';
import BadgePill from '@/vue/atoms/badge-pill.vue';
import {useUserStore} from '@/ts/store/user-store';
import FormRequired from '@/vue/atoms/form-required.vue';

const props = defineProps<{
  question: QuestionNumeric;
}>();

const model = defineModel<QuestionAnswerNumericBody|null>();

const {userData} = useUserStore();

const min = computed(() => {
  return props.question.numeric.minValue ?? 0;
});

const max = computed(() => {
  return props.question.numeric.maxValue ?? 0;
});

onBeforeMount(() => {
  if (!model.value) {
    let initVal = min.value;
    const birthYearPreset: number = userData?.birthYear ?? 1990;
    if (initVal >= 1900 && birthYearPreset && birthYearPreset >= min.value && birthYearPreset <= max.value ) {
      // special case: year of birth
      // min value for birth-year is delivered as 1900, that looks strange
      // set user-birth-year as default if known
      initVal = birthYearPreset;
    }
    model.value = {
      numeric: initVal,
      type: QuestionnaireType.NUMERIC,
    };
  }
});

const value = computed({
  set: (val: string) => {
    const valNum = parseInt(val, 10);
    if (Number.isNaN(valNum)) {
      model.value = null;
    } else {
      model.value = {
        numeric: Math.round(valNum),
        type: QuestionnaireType.NUMERIC,
      };
    }
  },
  get: (): number|undefined => {
    return model.value?.numeric;
  },
});

const isValid = computed((): boolean => {
  return Boolean(model.value) &&
    model.value!.numeric >= min.value &&
    model.value!.numeric <= max.value;
});

const inputLabelledby = computed(() => {
  return `question-${crypto.randomUUID()}`;
});

</script>
<template>
  <div class="question-numeric-component">
    <badge-pill theme="blue" :label="$t('question')"/>
    <div :id="inputLabelledby" class="question text-h6" v-html="question.name"/>
    <q-input v-model="value" class="input" type="number" :aria-labelledby="inputLabelledby"/>
    <form-required id="sdf" :valid="isValid" :message="$t('validation_number_error_msg', {min, max})"/>
  </div>
</template>
<style scoped lang="scss">
.question-numeric-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.question {
  margin: var(--Spacing-spacing-2) 0;
  text-align: center;
  max-width: 700px;
}

.input :deep(input) {
  text-align: center;
}

</style>
