import {
  computed,
  type ComputedRef,
} from 'vue';

import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {useRoute} from 'vue-router';
import type {
  InterventionPath,
  SessionPath,
  MainPath,
} from '@/ts/router/pages-path';


interface PathParams {
  [InterventionPath.interventionInfo]: { iid: UuidDTO };
  [InterventionPath.interventionInfoAbout]: { iid: UuidDTO };
  [InterventionPath.interventionRecap]: { iid: UuidDTO };
  [InterventionPath.interventionInfoSessions]: { iid: UuidDTO };
  [InterventionPath.interventionLanding]: { iid: UuidDTO };
  [SessionPath.sessionPage]: { iid: UuidDTO, sid: UuidDTO, pid: number };
  [MainPath.recommendation]: {contentType: string, catalogId: string, itemId: string };
}

type RequiredParams<T> = T extends keyof PathParams ? PathParams[T] : never;

export function getVueLink<Path extends keyof PathParams>(
  path: Path,
  args:  RequiredParams<Path>,
): string {
  let res = path as string;
  for (const [param, id] of Object.entries(args)) {
    res = res.replace(`:${param}`, id as string);
  }
  return res;
}

export function useRouterSectionParams(): ({
  iid: ComputedRef<UuidDTO>; // id of the intervention
  sid: ComputedRef<UuidDTO>; // id of the intervention session
  pid: ComputedRef<number>; // id of the intervention session page
  catalogId: ComputedRef<string>; // id of the intervention session page
  itemId: ComputedRef<string>; // id of the intervention session page
  contentType: ComputedRef<string>; // id of the intervention session page
}) {
  // eslint-disable-next-line no-restricted-syntax
  const route = useRoute();
  const iid = computed(() => route.params.iid as UuidDTO);
  const sid = computed(() => route.params.sid as UuidDTO);
  const pid = computed(() => route.params.pid ? parseInt(route.params.pid as string, 10): 0);
  const catalogId = computed(() => route.params.catalogId as string);
  const itemId = computed(() => route.params.itemId as string);
  const contentType = computed(() => route.params.contentType as string);
  return {iid, sid, pid, catalogId, itemId, contentType};
}
