<script lang="ts" setup>

import SvgIllustration from '@/vue/atoms/svg-illustration.vue';

defineProps<{
  title: string;
  icon?: string;
  theme: 'p1-3' | 'p3-7';
}>();
</script>
<template>
  <div class="no-click-btn" :class="{icon, [theme]: true}">
    <svg-illustration v-if="icon" :name="icon"/>
    <div class="no-click-btn-title">
      {{ title }}
    </div>
  </div>
</template>
<style lang="scss" scoped>

.icon {
  .no-click-btn-title {
    margin-left: var(--Spacing-spacing-1);
  }
}

.p3-7 {
  padding: var(--Spacing-spacing-3) var(--Spacing-spacing-7);
  @include font-body-bold;
}

.p1-3 {
  padding: var(--Spacing-spacing-1) var(--Spacing-spacing-3);
  @include font-body-sm-bold;
  width: fit-content;
}

.no-click-btn {
  display: flex;
  justify-content: center;
  border-radius: var(--button-border-radius);
  background-color: var(--Secondary-secondary-color-2);
}
</style>
