<script lang="ts" setup>
import {computed} from 'vue';
import {useLogger} from '@/ts/composables/pure/use-logger';

const props = defineProps<{
  markerLabels: string[];
  selectedIndex: number;
}>();

const emit = defineEmits<{
  clickOnMarker: [number],
}>();

const {warn} = useLogger();

const middleIndex = computed((): number => {
  if (props.markerLabels.length % 2 > 0) {
    return Math.floor(props.markerLabels.length / 2);
  }
  return -1; // no middle, because of even length
});

const markerLabels = computed((): string[] => {
  const r: string[] = [];
  props.markerLabels.forEach(label => {
    const {length} = r.filter((item) => (item === label));
    if (length) {
      warn(`Label "${label}" already exists. Counter will be added.`)();
      r.push(`${label} (${length + 1})`);
    } else {
      r.push(label);
    }
  });
  return r;
});

</script>

<template>
  <div class="horizontal-axis-with-scale" aria-hidden="true">
    <div
      v-for="(markerLabel, index) in markerLabels"
      :key="markerLabel"
      class="marker"
      :class="{
        'marker--selected': index === selectedIndex,
        'marker--first': index === 0,
        'marker--mid': index === middleIndex,
        'marker--last': index === markerLabels.length - 1,
      }"
      aria-hidden="true"
      @click="emit('clickOnMarker', index)"
    >
      <span class="marker-label text-metric-md">
        {{ markerLabel }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@mixin horizontal-axis-with-scale-component-broad {
  @container horizontal-axis-with-scale-component (min-width: 500px) {
    @content;
  }
}

.horizontal-axis-with-scale {
  display: flex;
  justify-content: space-between;
  container-type: inline-size;
  container-name: horizontal-axis-with-scale-component;
}

.marker {
  width: 1px;
  height: 10px;
  background-color: var(--Neutral-UI-neutral-ui-10);
  position: relative;
  transition: background-color .5s linear;

  &--selected {
    background-color: var(--Neutral-UI-neutral-ui-100);
  }

  &--first,
  &--last,
  &--mid {
    background-color: transparent;
  }

  &:before {
    // augment click-area
    content: "";
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -10px;
    right: -10px;
  }

  @include horizontal-axis-with-scale-component-broad {
    background-color: transparent;
  }
}

.marker-label {
  display: none;
  position: absolute;
  left: -50%;
  top: -2px;
  transform: translateX(-50%);
  white-space: nowrap;
  user-select: none;

  .marker--first & {
    transform: translateX(-15%);
    display: inline-block; // always visible
  }

  .marker--last & {
    transform: translateX(-85%);
    display: inline-block; // always visible
  }

  .marker--mid & {
    display: inline-block; // always visible
  }

  @include horizontal-axis-with-scale-component-broad {
    display: inline-block;
  }
}
</style>