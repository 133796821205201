<script setup lang="ts">
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import type {Questionnaire} from '@/ts/types/dto/interventions-dto';
import FormRequired from '@/vue/atoms/form-required.vue';

defineProps<{
  questionnaire: Questionnaire;
}>();

defineEmits<{
  open: [boolean]
}>();
</script>

<template>
  <q-btn v-if="questionnaire.isAnswered" class="questionnaire-completed" @click="$emit('open', true)">
    <div class="question-comp-tit">
      {{ $t('assessment') }}
    </div>
    <div class="question-comp-name">
      {{ questionnaire.name }}
    </div>
    <div v-if="questionnaire.score?.label" class="question-comp-tag">
      <span class="quest-tag-span"/>
      {{ questionnaire.score.label }}
    </div>
  </q-btn>
  <!-- Div is required so form-required doesn't add extra gaps for a hidden element -->
  <div v-else>
    <form-required
      :id="`questionnaire-result-block-${questionnaire.id}`"
      :valid="false"
      :message="$t('answer_questionnaire')"
    />
    <balm-btn
      class="button"
      :label="$t('start_assessment')"
      paddings="p3-7"
      theme="blue"
      icon="arrow-narrow-right.svg"
      @click="$emit('open', false)"
    />
  </div>
</template>
<style lang="scss" scoped>
.questionnaire-completed {
  border-radius: var(--card-border-radius);
  background: var(--Secondary-secondary-color-2);
  padding: var(--Spacing-spacing-3);
  width: 100%;

  @include screen-md {
    padding: var(--Spacing-spacing-5);
    gap: var(--Spacing-spacing-8);
  }

  :deep(.q-btn__content) {
    text-align: left;
    justify-content: flex-start;
    gap: var(--Spacing-spacing-1);

    @include screen-md {
      display: grid;
      grid-template-areas:
      "title tag"
      "name tag";
    }
  }
}

.question-comp-tit {
  grid-area: title;
  @include font-body-sm-bold;
}

.question-comp-name {
  @include font-h3-bold;
  grid-area: name;
}

.quest-tag-span {
  background-color: var(--Primary-primary-color-2);
  width: var(--Spacing-spacing-3);
  display: block;
  border-radius: 50%;
  height: var(--Spacing-spacing-3);
}

.question-comp-tag {
  margin-left: var(--Spacing-spacing-7);
  grid-area: tag;
  border-radius: var(--button-border-radius);
  padding: var(--Spacing-spacing-1) var(--Spacing-spacing-4) var(--Spacing-spacing-1) var(--Spacing-spacing-3);
  background-color: var(--Neutral-UI-neutral-ui-10);
  @include font-body-sm-bold;
  display: flex;
  align-items: center;
  gap: var(--Spacing-spacing-3)
}

.button {
  width: 100%;
  
  @include screen-sm {
    width: auto;
  }
}
</style>
