<script lang="ts" setup>
import ReccoRecommendationCollection from '@/vue/organisms/recco/recco-recommendation-collection.vue';
import ReccoFeaturedRecommendation from '@/vue/organisms/recco/recco-featured-recommendation.vue';
import {ref} from 'vue';
import ReccoAssessmentCard from '@/vue/organisms/recco/recco-assessment-card.vue';
import type {ReccoRecommendationDTO} from '@/ts/types/dto/recco/recommendation.dto';

const recommendationCollectionComponent = ref<typeof ReccoRecommendationCollection>();
const featuredRecommendation = ref<ReccoRecommendationDTO | null>(null);

// avoids that the assessment get rendered before top components (avoids page jump)
const areTopElementsReady = ref<boolean>(false);

const handleFeaturedRecommendationChange = (recommendation: ReccoRecommendationDTO | null): void => {
  featuredRecommendation.value = recommendation;
};

//TODO: Move logic for getting list of recommendations and featured recommendation to this page.

</script>

<template>
  <div class="explore-page">
    <recco-featured-recommendation 
      v-if="featuredRecommendation"
      :recommendation="featuredRecommendation"
    />
    <recco-recommendation-collection 
      ref="recommendationCollectionComponent"
      @featured-recommendation-change="handleFeaturedRecommendationChange"
      @ready="areTopElementsReady = true"
    />
    <recco-assessment-card
      v-if="areTopElementsReady"
      @complete-assessment="recommendationCollectionComponent?.refresh()"
    />
  </div>
</template>

<style lang="scss" scoped>
.explore-page {
  @include page-alignment;
}
</style>