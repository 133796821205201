<!-- eslint-disable id-length -->
<script lang="ts" setup>
import {onMounted, onUnmounted, ref, watch} from 'vue';

const props = defineProps<{
  label: string;
  hide: boolean;
}>();

const showing = ref<boolean>(false);
const position = ref<{x: number, y: number}>({x:0, y:0});
const anchor = ref<HTMLDivElement | null>(null);

function handleResize(): void {
  showing.value = false;
  // remove tooltip in first resize
  window.removeEventListener('resize', handleResize);
};

onMounted(() => {
  if (anchor.value) {
    const rect = anchor.value.getBoundingClientRect();
    // Magic numbers to position center left from the icon
    position.value = {x: rect.left - 170, y: rect.top - 6};
    showing.value = true;
  }
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {window.removeEventListener('resize', handleResize);});

watch(() => props.hide, (val) => {
  if (!val) {
    showing.value = false;
  }
});
</script>
<template>
  <div ref="anchor"/>
  <Transition name="tooltip" :css="hide">
    <Teleport v-if="showing" :to="anchor">
      <div
        class="sidebar-tooltip"
        :style="{top: `${position.y}px`, left: `${position.x}px`}"
        @mouseleave="showing = false"
      >
        <span class="text-body-sm">{{ label }}</span>
      </div>
    </Teleport>
  </Transition>
</template>
<style scoped lang="scss">
.sidebar-tooltip {
  position: fixed;
  background: var(--Neutral-UI-neutral-ui-100);
  color: var(--Neutral-UI-neutral-ui-0);
  border-radius: var(--border-radius);
  padding: var(--Spacing-spacing-3) var(--Spacing-spacing-4);

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid var(--Neutral-UI-neutral-ui-100);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
}

.tooltip-enter-active, .tooltip-leave-active {
  transition: all .8s ease-in-out;
}

.tooltip-enter-from, .tooltip-leave-to {
  opacity: 0;
}

.tooltip-enter-to, .tooltip-leave-from {
  opacity: 1;
}
</style>
