<script setup lang="ts">
import {pagesPath} from '@/ts/router/pages-path';
import {
  PublicationState,
  type Intervention, type Session,
} from '@/ts/types/dto/interventions-dto';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import LazyImage from '@/vue/atoms/lazy-image.vue';
import balmBtn from '@/vue/atoms/balm-btn.vue';
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';
import {useLoadingNotification} from '@/ts/composables/pure/use-loading-notification';
import {useApi} from '@/ts/composables/stateful/use-api';
import {useRouter} from 'vue-router';
import InterventionLabel from '@/vue/molecules/intervention-label.vue';
import BadgeZeppelin from '@/vue/atoms/badge-zeppelin.vue';
import {getVueLink} from '@/ts/composables/stateful/use-route-params';
import {useProvidedIntervention} from '@/ts/composables/pure/use-provided-intervention';
import {useSession} from '@/ts/composables/pure/use-provided-session';

const {t: $t} = useI18n();
const api = useApi();
const router = useRouter();

const props = defineProps<{ intervention: Intervention }>();

const intervention = computed(() => {
  return props.intervention;
});

const {currentSession} = useProvidedIntervention(intervention);
const pageSession = computed<Session>(() => intervention.value.sessions.find(ses => currentSession.value?.id === ses.id)!);
const {lastPageIndexCompleted} = useSession(intervention, pageSession);

const currentSessionLink = computed<string | null>(() => {
  //if (pageSession.value?.state !== SessionState.UNLOCK) {
  //  return null;
  //}
  let index = 0;
  if (lastPageIndexCompleted.value !== null
      && lastPageIndexCompleted.value < pageSession.value?.pages.length) {
    index = lastPageIndexCompleted.value;
  }
  return  getVueLink(pagesPath.session.sessionPage, {
    iid: props.intervention.id,
    sid: currentSession.value!.id,
    pid: index + 1,
  });
});

const theme = computed(() => {
  if (props.intervention.publicationState === PublicationState.IN_PROGRESS) {
    return 'blue';
  }
  if (props.intervention.publicationState === PublicationState.COMPLETED) {
    return 'green';
  }
  return 'grey';
});

const [enroll, enrollingInProgress] = useLoadingNotification(
  async() => api.me.enrollIntervention(props.intervention.id),
  () =>void router.push(currentSessionLink.value!),
);

</script>

<template>
  <div class="intervention-hero" :class="theme">
    <div class="flex justify-between items-center q-mb-sm">
      <q-btn class="back-btn focus-visible" flat dense :to="pagesPath.intervention.interventionsList">
        <svg-illustration name="chevron-left.svg" aria-hidden="true"/>
        <span class="label">{{ $t('all_paths') }}</span>
      </q-btn>
      <intervention-label :publication-state="intervention.publicationState"/>
    </div>
    <div class="grid-container">
      <div class="image">
        <lazy-image :src="intervention.image!"/>
      </div>
      <div class="info">
        <badge-zeppelin class="caption" :label="$t('path')"/>
        <h1 class="title">
          {{ intervention.name }}
        </h1>
        <balm-btn
          v-if="intervention.publicationState === PublicationState.IN_PROGRESS && currentSessionLink"
          :label="$t('continue')"
          :to="currentSessionLink!"
          theme="outline"
          paddings="p3-7"
          icon="arrow-narrow-right.svg"
        />
        <balm-btn
          v-else-if="![PublicationState.COMPLETED, PublicationState.IN_PROGRESS].includes(intervention.publicationState)"
          paddings="p3-7"
          theme="blue"
          class="enroll-btn"
          :label="$t('enroll')"
          :loading="enrollingInProgress"
          :disable="enrollingInProgress"
          @click="enroll"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.intervention-hero {
  border-radius: 12px;
  min-height: 300px;
  padding: 24px 32px 24px 24px;
  background-color: var(--Neutral-UI-neutral-ui-20);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.blue {
    background-color: var(--Secondary-secondary-color-1);
  }
  &.green {
    background-color: var(--Secondary-secondary-color-2);
  }

  @include screen-md {
    min-height: 500px;
    padding: 32px 42px 32px 32px;
  }

  @include screen-lg {
    padding: 40px 48px 40px 40px;
  }
}

.back-btn {
  @include font-body-bold;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 12px;
  grid-template-areas:
    'image'
    'info';
  align-items: flex-end;

  @include screen-md {
    gap: 16px;
  }

  @include screen-lg {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'info image';
    gap: 24px;
  }
}

.info {
  grid-area: info;
}

.image {
  grid-area: image;
}

.caption {
  margin-bottom: 4px;

  @include screen-md {
    margin-bottom: 8px;
  }

  @include screen-lg {
    margin-bottom: 12px;
  }
}

.title {
  margin-bottom: 16px;

  @include screen-md {
    margin-bottom: 24px;
  }

  @include screen-lg {
    margin-bottom: 32px;
  }
}
</style>
