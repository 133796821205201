<script lang="ts" setup>
import {useLoadingData} from '@/ts/composables/pure/use-loading-data-ref';
import {useApi} from '@/ts/composables/stateful/use-api';
import QuestionnaireStandardModal from '@/vue/molecules/components/questionnaire/questionnaire-standard-modal.vue';
import {computed, ref, watchEffect} from 'vue';
import {
  QuestionDisplayType,
  QuestionnaireType,
  QuestionType,
  type Option,
  type Question,
  type Questionnaire,
} from '@/ts/types/dto/interventions-dto';
import {QuestionnaireType as QuestionnaireQuestionType, type SessionRequestQuestionnaire} from '@/ts/types/dto/session-request-dto';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {
  type ReccoCreateQuestionnaireAnswerDTO,
  type ReccoQuestionDTO,
  type ReccoQuestionnaireResponse,
  ReccoQuestionAnswerType,
} from '@/ts/types/dto/recco/questionnaire.dto';
import LoadingError from '@/vue/templates/loading-error.vue';
import BalmDialog from '@/vue/atoms/balm-dialog.vue';
import ReccoQuestionnaireIntro from '@/vue/organisms/recco/recco-questionnaire-intro.vue';
import ReccoQuestionnaireOutro from '@/vue/organisms/recco/recco-questionnaire-outro.vue';
import {getNulledUuid} from '@/ts/utils/pure-functions';
import {ReccoQuestionnaireCategory, type ReccoQuestionnaireType} from '@/ts/types/component/recco-questionnaire.type';
import {useLogger} from '@/ts/composables/pure/use-logger';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  questionnaireType: ReccoQuestionnaireType;
}>();

const emit = defineEmits<{
  completeQuestionnaire: [],
}>();

const isOpen = defineModel<boolean>();
const {t: $t} = useI18n();
const api = useApi();

const step = ref<number>(0);
const savingInProgress = ref<boolean>(false);
const answers = ref<SessionRequestQuestionnaire[] | null>(null);

const {error: logError} = useLogger();

const reccoQuestionnaire = ref<ReccoQuestionnaireResponse>();
const [fetchQuestionnaireFn, loading, error] = useLoadingData(
    reccoQuestionnaire,
    async() => api.recco.getQuestionnaire(props.questionnaireType.category, props.questionnaireType.id),
);

const answerIdMapping = computed<Map<UuidDTO, number>>(() => {
  const mapping = new Map<UuidDTO, number>();
  (reccoQuestionnaire.value ?? []).forEach((reccoQuestion: ReccoQuestionDTO) => {
    (reccoQuestion.multiChoice?.options ?? []).forEach(reccoOption => {
      mapping.set(reccoOption.id, reccoOption.reccoId);
    });
  });
  return mapping;
});

const mappingQuestionnaireIdByQuestionId = computed<Map<string, string>>(() => {
  // Each Recco-Question can have an individual questionnaire-id
  // Here is a mapping questionId and questionnaireId
  const mapping = new Map<string, string>();
  (reccoQuestionnaire.value ?? []).forEach((reccoQuestion: ReccoQuestionDTO) => {
    mapping.set(reccoQuestion.reccoQuestionId, reccoQuestion.reccoQuestionnaireId);
  });
  return mapping;
});

const questionnaire = computed<Questionnaire>(() => {
  const questions = (reccoQuestionnaire.value ?? []).map<Question>((reccoQuestion: ReccoQuestionDTO) => {
    const options: Option[] = (reccoQuestion.multiChoice?.options ?? []).map(reccoOption => {
      return {
        id: reccoOption.id.toString() as UuidDTO,
        text: reccoOption.text,
        value: reccoOption.value,
        defaultAnswer: false,
      };
    });

    return {
      userComponentId: reccoQuestion.contentId,
      contentId: reccoQuestion.reccoQuestionId,
      type: reccoQuestion.type === ReccoQuestionAnswerType.MultiChoice ? QuestionType.MULTICHOICE : QuestionType.NUMERIC,
      name: reccoQuestion.name,
      description: 'description',
      image: null,
      requiresHarmReductionCheck: false,
      isAnswered: false,

      multiChoice: {
        maxOptions: reccoQuestion.multiChoice?.maxOptions,
        minOptions: reccoQuestion.multiChoice?.minOptions,
        options,
      },
      displayType: QuestionDisplayType.ORDERED_LIST,
      multiChoiceSelectedIds: [],

      numeric: {
        maxValue: reccoQuestion.numeric?.maxValue,
        minValue: reccoQuestion.numeric?.minValue,
        format: reccoQuestion.numeric?.format,
      },
      numericSelected: 0,
    } as Question;
  });

  return {
    id: getNulledUuid(), // Recco questionnaires has no reliable id :-( don't use this value
    name: $t('recco_assessment_welcome_caption'),
    description: '',
    questions,
    citations: [], // legacy
    score: null,
    hasScore: false,
    isAnswered: false,
    type: QuestionnaireType.STANDARD,
    image: null,
  } as Questionnaire;
});

const onContinueWithNextStep = (): void => {
  step.value++;
};

const onFinishQuestionnaire = async(): Promise<void> => {
  const answersRequestBody: ReccoCreateQuestionnaireAnswerDTO[] = [];

  if (Array.isArray(answers.value)) {
    answers.value.forEach((answer) => {
      let multiChoiceRecco;
      if (answer.questionnaireAnswers.answers.type === QuestionnaireQuestionType.MULTICHOICE) {
        multiChoiceRecco = (answer.questionnaireAnswers.answers.multiChoice || []).map((answerUuid: UuidDTO) => {
          return answerIdMapping.value.get(answerUuid)!;
        });
      }

      let numeric;
      if (answer.questionnaireAnswers.answers.type === QuestionnaireQuestionType.NUMERIC) {
        //eslint-disable-next-line @typescript-eslint/prefer-destructuring
        numeric = answer.questionnaireAnswers.answers.numeric;
      }

      const typeMapping = new Map<QuestionnaireQuestionType, ReccoQuestionAnswerType>([
        [QuestionnaireQuestionType.MULTICHOICE, ReccoQuestionAnswerType.MultiChoice],
        [QuestionnaireQuestionType.NUMERIC, ReccoQuestionAnswerType.Numeric],
      ]);

      const reccoQuestionId = answer.questionnaireAnswers.questionId!;
      const reccoQuestionnaireId = mappingQuestionnaireIdByQuestionId.value.get(reccoQuestionId)!;

      answersRequestBody.push({
        reccoQuestionnaireId,
        reccoQuestionId,
        answers: {
          type: typeMapping.get(answer.questionnaireAnswers.answers.type)!,
          multiChoiceRecco,
          numeric,
        },
      });
    });
  }

  try {
    savingInProgress.value = true;
    const isOnboarding = props.questionnaireType.category === ReccoQuestionnaireCategory.ONBOARDING;
    await api.recco.postQuestionnaireAnswers(answersRequestBody, isOnboarding);
    onContinueWithNextStep();
    emit('completeQuestionnaire');
  } catch (err: any) { //eslint-disable-line @typescript-eslint/no-unsafe-call
    logError('Save questionnaire answers failed.')();
  } finally {
    savingInProgress.value = false;
  }
};

watchEffect(() => {
  if (isOpen.value) {
    void fetchQuestionnaireFn();
  } else {
    step.value = 0;
  }
});

</script>

<template>
  <balm-dialog v-model="isOpen" type="h900" no-scroll theme="grey-10">
    <recco-questionnaire-intro
      v-if="step === 0"
      :category="questionnaireType.category"
      @click-continue="onContinueWithNextStep"
    />

    <loading-error
      v-if="step === 1"
      id="recco-questionnaire-modal"
      :loading="loading"
      :error="error"
      type="component"
    >
      <questionnaire-standard-modal
        v-model="answers"
        v-model:is-modal-open="isOpen"
        :questionnaire="questionnaire"
        :loading="savingInProgress"
        embedded
        @finish="onFinishQuestionnaire"
      />
    </loading-error>

    <recco-questionnaire-outro
      v-if="step === 2"
      :category="questionnaireType.category"
      @done="isOpen = false"
    />
  </balm-dialog>
</template>