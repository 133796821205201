<script lang="ts" setup>
import {ReccoRecommendationTypeDTO} from '@/ts/types/dto/recco/recommendation.dto';
import {computed} from 'vue';
import {formatDuration} from '@/ts/utils/pure-functions';

const props = withDefaults(defineProps<{
  type: ReccoRecommendationTypeDTO;
  duration?: number|null;
}>(), {
  duration: null,
});

const labelLanguageKey = computed((): string => {
  return {
    [ReccoRecommendationTypeDTO.Article]: 'recco_media_type_articles',
    [ReccoRecommendationTypeDTO.Audio]: 'recco_media_type_audios',
    [ReccoRecommendationTypeDTO.Video]: 'recco_media_type_videos',
    [ReccoRecommendationTypeDTO.Questionnaire]: 'recco_media_type_questionnaire',
  }[props.type];
});



const minutes = computed((): string => {
  return props.duration ? formatDuration(props.duration) : '';
});

</script>

<template>
  <div class="media-type-and-duration">
    <span
      v-if="labelLanguageKey"
      class="media-type-label"
    >
      {{ $t(labelLanguageKey) }}
    </span>
    <span
      v-if="minutes"
      class="media-type-label"
    >
      {{ $t('recco_duration_min', {val: minutes}) }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.media-type-and-duration {
  opacity: 0.6;
  color: var(--neutral-UI-neutral-60);
}

.media-type-label {
  @include font-body-sm;
}

</style>