<script setup lang="ts">
import CompletedProgress from '@/vue/atoms/completed-progress.vue';
import {getVueLink, useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';
import SessionTocItem from '@/vue/organisms/session-toc-item.vue';
import {useSessionId} from '@/ts/composables/stateful/use-session';
import {
  computed, getCurrentInstance,
  onMounted,
  ref,
} from 'vue';
import BackLink from '@/vue/molecules/back-link.vue';
import {useInterventionStore} from '@/ts/store/intervention-store';
import {pagesPath} from '@/ts/router/pages-path';
import {useScreenSize} from '@/ts/composables/stateful/use-screen-size';

const props = defineProps<{
  neverScaleDown?: boolean // possibility to disable menu-scale-down for legacy reasons
}>();

const nav = ref<HTMLElement>();
const tocItems = ref();

const intervention = useInterventionStore();
const {iid, sid} = useRouterSectionParams();
const {session, order, completedPercent} = useSessionId(sid);
const wrapper = ref<HTMLElement>(null!);

const instance = getCurrentInstance();
const interventionLandingLink = getVueLink(pagesPath.intervention.interventionLanding, {iid: iid.value});

const {isMobileResolution} = useScreenSize();
const isMenuScaledDownUnlockedByUser = ref<boolean>(false);
const isMenuScaledDown = computed<boolean>(() => {
  return !props.neverScaleDown &&
      isMobileResolution.value &&
      !isMenuScaledDownUnlockedByUser.value;
});

const navId = computed<string>(():string => {
  return `session-navigation-${  instance?.uid}`;
});

const boundaryInlineStyle = ref<string>('');

function onClickShowAllMenuItems(): void {
  isMenuScaledDownUnlockedByUser.value = true;

  // focus first nav item (needed for keyboard control)
  if (tocItems.value?.[0]) { //eslint-disable-line
    tocItems.value[0].focus(); //eslint-disable-line
  }

  // ensure transition animation
  boundaryInlineStyle.value =
      nav.value?.offsetHeight ?
          `height: ${nav.value?.offsetHeight}px; overflow: hidden;`
          : 'auto';
  window.setTimeout(() => {
    boundaryInlineStyle.value = '';
  }, 500);
}

onMounted(() => {
  wrapper.value.scrollTop = wrapper.value.scrollHeight;
});
</script>
<template>
  <div ref="wrapper">
    <back-link :label="intervention!.name" :to="interventionLandingLink!"/>
    <div class="text-body-sm-bold">
      {{ $t('session_index', {order}) }}
    </div>
    <completed-progress :completed="completedPercent"/>
    <div
      class="nav-boundary"
      :class="{'nav-boundary--scaled-down': isMenuScaledDown}"
      :style="boundaryInlineStyle"
    >
      <q-btn
        v-if="isMenuScaledDown"
        class="btn-show-all"
        :aria-expanded="!isMenuScaledDown"
        :aria-controls="navId"
        :aria-label="$t('session_nav_expand_btn_aria')"
        flat
        dense
        @click="onClickShowAllMenuItems"
      >
        <span class="text-body-sm-bold">{{ $t('session_nav_expand_btn') }}</span>
      </q-btn>
      <nav :id="navId" ref="nav" :aria-label="$t('session_nav')">
        <ol ref="navList" class="nav-session-pages">
          <session-toc-item
            v-for="(page, i) in session!.pages"
            ref="tocItems"
            :key="page.id"
            :index="i + 1"
            :page="page"
            :is-inactive="isMenuScaledDown"
          />
        </ol>
      </nav>
    </div>
  </div>
</template>
<style scoped lang="scss">
.nav-session-pages {
  @include dashed-between-elements(var(--Spacing-spacing-2));
  width: 100%;
  margin: 0;
  padding: 0;
}

.nav-boundary {
  // has inline-style set by JavaScript in order to set height for the transition
  transition: height .3s linear;
  margin: var(--Spacing-spacing-5) -5px 0 -5px;
  padding: 5px; // give room for outline of :focus-visible (keyboard usage)

  &--scaled-down {
    height: 300px;
    overflow: hidden;
    position: relative;


    &:after {
      content: "";
      background: linear-gradient(transparent, var(--Neutral-UI-neutral-ui-10));
      position: absolute;
      bottom: 0px;
      left: 0;
      height: 100%;
      width: 100%;
    }

    @include screen-lg {
      max-height: none;
      &:after {
        content: none;
      }
    }
  }
}

.btn-show-all {
  @include focus-visible;
  position: absolute;
  bottom: 10px;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 0);

  @include screen-lg {
    display: none;
  }

}
</style>
