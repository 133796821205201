<script lang="ts" setup>
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {
  computed,
  onBeforeMount,
} from 'vue';
import type {
  Option,
  QuestionMultiChoice,
} from '@/ts/types/dto/interventions-dto';
import LazyImage from '@/vue/atoms/lazy-image.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';

const model = defineModel<UuidDTO[]>();

const props = defineProps<{
  question: QuestionMultiChoice;
}>();

const options = computed(() => props.question.multiChoice.options);
const logger = useLogger();
const trueOption = computed<Option>(() => options.value.find(op => Boolean(op.value))!);
const falseOption = computed<Option>(() => options.value.find(op => !op.value)!);

const val = computed<boolean>({
  get: () => trueOption.value.id === model.value![0]!,
  set: (value) => {
    if (value) {
      model.value = [trueOption.value.id];
    } else {
      model.value = [falseOption.value.id];
    }
  },
});


onBeforeMount(() => {
  logger.debug('onBeforeMount')();
  model.value = [falseOption.value.id];
});

</script>
<template>
  <q-btn flat class="btn" :class="{active: val}" @click="val = !val">
    <div class="row">
      <lazy-image v-if="question.image" :src="question.image" class="img" :height="90"/>
      <q-checkbox v-model="val" class="check"/>
    </div>
    <div class="question">
      {{ question.name }}
    </div>
    <div class="description" v-html="question.description"/>
  </q-btn>
</template>
<style scoped lang="scss">
.question {
  margin: var(--Spacing-spacing-7) 0 var(--Spacing-spacing-3) 0;
  width: 100%;
  text-align: start;
  @include font-body-bold;
}

.description {
  @include font-body-sm;
}

.row {
  display: flex;
  width: 100%;
  flex-direction: row;

  .img {
    flex: 10;
    width: 100px;
    height: 100px;
    border-radius: var(--card-border-radius);
  }

  .check {
    display: none;
    margin-top: -10px;
    margin-right: -10px;
    align-self: start;
    flex: 1;
  }
}

.check:hover :deep(.q-checkbox__inner:before) {
  transform: scale3d(0, 0, 1) !important;
}

.btn {
  width: 100%;
  height: 100%;
  text-transform: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--card-border-radius);
  background-color: var(--Neutral-UI-neutral-ui-10);
  padding: var(--Spacing-spacing-4);

  @include screen-md {
    padding: var(--Spacing-spacing-7) var(--Spacing-spacing-7) var(--Spacing-spacing-9) var(--Spacing-spacing-7);
  }

  :deep(.q-btn__content ) {
    text-align: left;
    justify-content: left;
    flex-direction: column;
  }

  &.active {
    background: var(--Neutral-UI-neutral-ui-60);

    .question {
      color: var(--Neutral-UI-neutral-ui-0);
    }

    .description {
      color: var(--Neutral-UI-neutral-ui-20);
    }
  }
}
</style>
